var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view"},[_c('div',{staticClass:"heading-1"},[_vm._v(" Agents ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.agents,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"no-decoration",attrs:{"to":("/agent/" + (item.id))}},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.instructor",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.hasRole(index, 'instructor'))?_c('span',[_vm._v(" Yes ")]):_vm._e()]}},{key:"item.sales",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.hasPermissions(index, 'sales'))?_c('span',[_vm._v(" Yes ")]):_vm._e()]}},{key:"item.account_management",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.hasPermissions(index, 'account-management'))?_c('span',[_vm._v(" Yes ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }