<template>
  <v-card class="fill-height pd-10" outlined>
    <v-card-title class="pd-5">
      <div class="text-center w100">
        This Month
      </div>
    </v-card-title>

    <v-divider />

    <Keys2Table :data="usageMonth" :keys="keys" />
  </v-card>
</template>

<script>
// mixins 
import metrics from '@/mixins/metrics.js'

// components
import Keys2Table from '@/components/metrics/templates/Keys2Table'

export default {
  mixins: [metrics],

  data () {
    return {
      keys: [
        'account-creations',
        'sessions',
        'submissions',
        'passed-submissions',
        'failed-submissions'
      ]
    }
  },

  components: {
    Keys2Table
  }
}
</script>

<style>

</style>