import Login from '@/views/Login'

export default [
  {
    path: '/',
    name: 'home-page',
    component: Login,
    
    meta: {
      layout: 'public-layout',
      public: true
    }
  }
]