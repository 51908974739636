// eslint-disable-next-line no-unused-vars
import moment from 'moment'

// stores
import ui from '@/stores/ui.js'

// mixin
export default {
  data () {
    return {
      loading: false
    }
  },

  computed: {
    /*
     *
     */
    overlay () {
      return ui.getters.overlay
    },

    /*
     * Persistent Snackbar
     */
    persistentSnackbar () {
      return ui.getters.persistentSnackBar
    },

    /*
     * Persistent Snackbar text
     */
    persistentSnackbarText () {
      return ui.getters.persistentSnackBarText
    },

    /*
     *
     */
    snackbar () {
      return ui.getters.snackbarStatus
    },

    /*
     *
     */
    snackbarColor () {
      return ui.getters.snackbarColor
    },

    /*
     *
     */
    snackbarText () {
      return ui.getters.snackbarText
    },

    /*
     *
     */
    snackbarTimeout () {
      return ui.getters.snackbarTimeout
    },

    /*
     *
     */
    submissionDialog () {
      return ui.getters.submissionDialog
    },

    /*
     *
     */
    submissionDialogId () {
      return ui.getters.submissionDialogId
    }
  },

  methods: {
    /*
     *
     */
    ago (timestamp) {
      return moment.tz(timestamp.toDate(), 'Australia/Victoria').fromNow(true)
    },

    /*
     *
     */
    ago2 (dt) {
      return moment.tz(new Date(dt), 'Australia/Victoria').fromNow(true)
    },

    /*
     * Opens a submission dialog in fullscreen
     */
    closeSubmissionDialog () {
      ui.commit('closeSubmissionDialog')
    },
    
    /*
     *
     */
    closePersistentSnackbar: function () {
      ui.commit('closePersistentSnackbar')
    },

    /*
     *
     */
    closeSnackbar: function () {
      ui.commit('closeSnackbar')
    },

    /*
     *
     */
    dataCss (val, n) {
      return (val >= n) ? 'red--text' : 'green--text'
    },
    
    /*
     *
     */
    formatDate (dt) {
      return moment.tz(new Date(dt), 'Australia/Victoria').format('DD-MM-YYYY HH:mm')
    },

    /*
     * Turns a string like "Windows Internals" to "windows-internals".
     * This is helpful to generate object keys.
     */
    formatKey (text) {
      return text.toLowerCase().split(' ').join('-')
    },

    /*
     * Turns a string like "specialist-bundle-metasploit" into "Specialist Bundle Metasploit"
     */
    formatText (text) {
      if (text.toLowerCase() === 'devsecops') {
        return 'DevSecOps'
      }

      if (text.toLowerCase() === 'grc') {
        return 'GRC'
      }

      if (text.toLowerCase() === 'certification-oscp') {
        return 'OSCP'
      }

      if (text.toLowerCase() === 'career-bundle-junior-red-teamer') {
        return 'Junior Red Teamer'
      }

      if (text.toLowerCase() === 'kickstarter-bundle-introductory-digital-forensics') {
        return 'Digital Forensics'
      }

      if (text.toLowerCase() === 'specialist-bundle-metasploit') {
        return 'Metasploit'
      }

      if (text.toLowerCase() === 'master-bundle-general-programming') {
        return 'Programming'
      }

      if (text.toLowerCase() === 'mics-introduction-to-cyber-security') {
        return 'MICS'
      }
      
      return text
        .split('-').join(' ')
        .replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        })
    },

    /*
     * Opens a submission dialog in fullscreen
     */
    openSubmissionDialog (submissionId) {
      ui.commit('openSubmissionDialog', submissionId)
    },

    /*
     * Opens a URL in a new tab
     */
    openTab (url) {
      window.open(url, '_blank')
    },

    /*
     * Scrolling function helper
     */
    scrollTo (refName) {
      var element = this.$refs[refName]

      if (Array.isArray(element) && element.length === 1) {
        element = element[0]
      }

      element.scrollIntoView()
    },

    /*
     * Randomly shuffle an array
     * https://stackoverflow.com/a/2450976/1293256
     * @param  {Array} array The array to shuffle
     * @return {String}      The first item in the shuffled array
     */
    shuffle (array) {
      var currentIndex = array.length
      var temporaryValue, randomIndex

      // While there remain elements to shuffle...
      while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1

        // And swap it with the current element.
        temporaryValue = array[currentIndex]
        array[currentIndex] = array[randomIndex]
        array[randomIndex] = temporaryValue
      }

      return array
    },

    /*
     * Shows the loading overlay
     */
    startOverlay () {
      ui.commit('changeOverlay', true)
    },

    /*
     * Hides the loading overlay
     */
    stopOverlay () {
      ui.commit('changeOverlay', false)
    },
    
    /*
     * Displays a RED error message
     */
    throwError (error) {
      this.stopOverlay()

      ui.commit('triggerErrorSnackbar', error)
    },

    /*
     * Displays a GREEN success message
     */
    throwSuccess (message) {
      this.stopOverlay()
      
      ui.commit('triggerSuccessSnackbar', message)
    }
  }
}
