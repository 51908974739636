var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view"},[(!_vm.submissions)?_c('v-progress-linear',{attrs:{"color":"green","indeterminate":""}}):_c('div',[_c('div',{staticClass:"heading-1"},[_vm._v(" Formidable Solutions ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.openSubmissionDialog(item.id)}}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.trophy",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":(item.trophy === 'gold') ? 'yellow darken-4' : 'white',"x-small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-trophy ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.trophy)+" ")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }