<template>
  <div>
    <!-- Loading bar -->
    <v-progress-linear v-if="!loaded" color="green" :indeterminate="true"></v-progress-linear>

    <div v-else>
      <v-data-table
        :headers="headers"
        :items="items"
        dense
        items-per-page="100"
        sort-by="engagement"
        sort-desc="false"
        >
        <!-- Email -->
        <template v-slot:item.email="{ item }">
          <router-link :to="`/student/${item.uid}`" class="no-decoration">
            {{ item.email }}
          </router-link>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase'

export default {
  props: ['date', 'type'],
  
  data () {
    return {
      headers: [
        {
          sortable: false,
          text: 'Email',
          value: 'email'
        },

        {
          sortable: false,
          text: 'Engagement',
          value: 'engagement'
        }
      ],

      items: null
    }
  },

  computed: {
    /*
     *
     */
    loaded () {
      return this.items && this.items.length
    }
  },

  /*
   * Firestore Connector
   */
  firestore () {
    return {
      items: firebase.firestore()
        .collection('analytics')
        .where('type', '==', this.type)
        .where('creationdate', '>', this.date)
    }
  }
}
</script>

<style>

</style>