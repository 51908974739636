/*
 * This is a global mixin to avoid code reuse of user input validation for the front-end
 */
export default {
  data () {
    return {
      rules: {
        /*
         *
         */
        date: v => /^\d{4}-\d{2}-\d{2}$/.test(v) || 'Invalid Date YYYY-MM-DD',

        /*
         *
         */
        linkedin: v => /^http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?$/i.test(v) || 'Invalid LinkedIn URL',

        /*
         *
         */
        time: v => /([0-2][0-9])((.|:)([0-5][0-9]))?/i.test(v) || 'Invalid Time. Use 24 hour format: hh:mm',

        /*
         *
         */
        length: len => v => (v || '').length >= len || `Invalid character length, required ${len}`,

        /*
         *
         */
        maxlength: len => v => (v || '').length <= len || 'Field is invalid',

        /*
         *
         */
        present: v => (v || '').length >= 1 || 'Field is required',
        
        /*
         *
         */
        email: v => /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v) || 'E-mail must be valid',
        
        /*
         *
         */
        phone: v => /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(v) || 'Phone number is invalid',
        
        /*
         *
         */
        noSpecialCharacters: v => /^[0-9a-zA-Z\b]+$/.test(v) || 'Only letters and numbers allowed',

        /*
         *
         */
        noSpecialCharactersExceptSpace: v => /^[0-9a-zA-Z \b]+$/.test(v) || 'Field cannot contain special characters',
        
        /*
         *
         */
        question: v => (v || '').endsWith('?') || 'Question must end with a ?',

        /*
         *
         */
        uuid: v => /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/i.test(v) || 'Invalid UUID',
        
        /*
         *
         */
        validQuestion: v => (v || '').toLowerCase().startsWith('how') || (v || '').toLowerCase().startsWith('what') || 'Question must start with "how" or "what". Other types of questions are not allowed',

        /*
         *
         */
        youtube: v => /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/.test(v) || 'Invalid Youtube URL'
      }
    }
  }
}
