import AbandonedExercises from '@/views/alerts/AbandonedExercises'
import Enterprise from '@/views/alerts/roles/Enterprise'
import Index from '@/views/alerts/Index'
import MostFailedExercises from '@/views/alerts/MostFailedExercises'

export default [
  {
    path: '/audits',
    name: 'audits-index',
    component: Index
  },

  {
    path: '/audits/abandoned-exercises',
    name: 'abandoned-exercises',
    component: AbandonedExercises
  },

  {
    path: '/audits/most-failed-exercises',
    name: 'most-failed-exercises',
    component: MostFailedExercises
  },

  {
    path: '/audits/roles/enterprise',
    name: 'audits-roles-enterprise',
    component: Enterprise
  }
]