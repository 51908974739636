import Index from '@/views/messages/Index'
import View from '@/views/messages/View'

export default [
  {
    path: '/messages',
    name: 'messages-index',
    component: Index
  },

  {
    path: '/messages/thread/:threadId',
    name: 'thread',
    component: View
  }
]