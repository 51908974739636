<template>
  <div class="view">
    <!-- Loading bar -->
    <v-progress-linear v-if="!loaded" color="green" :indeterminate="true"></v-progress-linear>

    <!-- Content -->
    <div v-else>
      <div class="heading-1">
        Orders
      </div>

      <!-- Search Field -->
      <v-text-field v-model="search"
        class="mb-15 search-field"
        clearable
        dense
        filled
        hide-details
        prepend-inner-icon="mdi-magnify"
        rounded
      ></v-text-field>

      <!-- Table -->
      <v-data-table
        :items="items"
        :headers="headers"
        :page.sync="page"
        :search="search"
        class="elevation-1"
        >
        <!-- Email -->
        <template v-slot:item.email="{ item }">
          <router-link :to="`/student/${item.id}`" class="no-decoration">
            {{ item.email }}
          </router-link>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase'

// mixins
import platform from '@/mixins/platform.js'
import ui from '@/mixins/ui.js'

// component
export default {
  mixins: [platform, ui],
  
  data () {
    return {
      headers: [
        {
          sortable: false,
          text: 'Email',
          value: 'email'
        },

        {
          sortable: false,
          text: 'Invoice Nb.',
          value: 'invoice'
        },

        {
          sortable: false,
          text: 'Item(s)',
          value: 'items'
        },

        {
          sortable: false,
          text: 'Total',
          value: 'total'
        },

        {
          sortable: false,
          text: 'Date',
          value: 'date'
        }
      ],

      orders: [],
      page: 1,
      search: ''
    }
  },

  computed: {
    /*
     *
     */
    loaded () {
      return this.items && this.items.length
    },

    /*
     *
     */
    items () {
      if (!this.orders.length) {
        return []
      }

      var orders = this.filter(this.orders)
      var res = []

      for (var i = 0; i < orders.length; i++) {
        var order = orders[i]

        res.push({
          id: order.content.metadata.platformUserId,
          email: order.content.email,
          invoice: order.content.invoiceNumber,
          items: this.showCurriculums(order.content.items),
          total: `$${order.content.total}`,
          date: this.formatDate(order.createdOn)
        })
      }

      return res
    }
  },

  methods: {
    /*
     *
     */
    filter (orders) {
      return orders.filter(i => i.content.total !== 0)
    },

    /*
     *
     */
    showCurriculums (items) {
      var out = ''

      for (var i = 0; i < items.length; i++) {
        var item = this.formatText(items[i].id)

        if (i === 0) {
          out = item
        } else {
          out += ', ' + item
        }
      }

      return out
    }
  },

  /*
   * Firestore Connector
   */
  firestore () {
    return {
      orders: firebase.firestore()
        .collection('orders')
        .where('eventName', '==', 'order.completed')
        .orderBy('createdOn', 'desc')
    }
  }
}
</script>

<style>

</style>