<template>
  <div class="view">
    <!-- Loading bar -->
    <v-progress-linear v-if="!loaded" color="green" :indeterminate="true"></v-progress-linear>

    <!-- View -->
    <div v-else>
      <div class="heading-1">
        Students who browsed the shopping cart in the last 7 days
      </div>

      <!-- Table -->
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="500"
        sort-by="timestamp"
        sort-desc
        dense
        >
        <!-- Email -->
        <template v-slot:item.email="{ item }">
          <router-link :to="`/student/${item.uid}`" class="no-decoration">
            {{ item.email }}
          </router-link>
        </template>

        <!-- Text -->
        <template v-slot:item.text="{ item }">
          {{ item.text }}

          <v-icon v-if="showIcon(item.text)" color="red" x-small>
            mdi-star
          </v-icon>
        </template>

        <!-- Timestamp -->
        <template v-slot:item.timestamp="{ item }">
          <span v-if="item['timestamp']">
            {{ ago(item['timestamp']) }}
          </span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase'

// mixins
import time from '@/mixins/time.js'
import ui from '@/mixins/ui.js'

export default {
  mixins: [time, ui],

  data () {
    return {
      headers: [
        {
          sortable: false,
          text: 'Email',
          value: 'email'
        },

        {
          sortable: false,
          text: 'Message',
          value: 'text'
        },

        {
          sortable: false,
          text: 'Timestamp',
          value: 'timestamp'
        }
      ],

      items: null
    }
  },

  computed: {
    /*
     *
     */
     date () {
      return this.dateKey.subtract(7, 'd').toDate()
    },

    /*
     * Returns TRUE if the data has loaded
     */
    loaded () {
      return this.items && this.items.length > 0
    }
  },

  methods: {
    /*
     * Returns TRUE if we show the icon
     */
    showIcon (text) {
      return text && (text.includes('Browsed') || text.includes('Added'))
    },

    /*
     * Format timestamp
     */
    timestamp (dt) {
      return new Date(dt.toDate())
    }
  },

  /*
   * Firestore Connector
   */
   firestore () {
    return {
      items: firebase.firestore()
        .collection('activity-logs')
        .where('sanitized', '==', true)
        .where('type', '==', 'sales')
        .where('timestamp', '>', this.date)
    }
  }
}
</script>

<style>

</style>