<template>
  <div>
    <!-- List Threads -->
    <v-card>
      <List v-if="threads" :items="threads" />

      <v-card-actions>
        <v-spacer />

        <v-btn @click="dialog = true;" color="green" small>
          Create
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Dialog -->
    <v-dialog v-model="dialog" width="800">
      <v-card>
        <v-card-title>
          New Thread
        </v-card-title>

        <v-divider />

        <v-card-text class="mt-20">
          <!-- Title -->
          <v-text-field v-model="title"
            color="green"
            counter="200"
            filled
            label="Title"
            >
          </v-text-field>

          <!-- Message -->
          <v-textarea v-model="text"
            class="mt-20"
            color="green"
            counter="2000"
            filled
            height="300"
            label="Message"
          ></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn @click="send()"
            :disabled="!canSend"
            :loading="loading"
            color="green"
            small
            >
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase'

// mixins
import firestore from '@/mixins/firestore.js'
import ui from '@/mixins/ui.js'
import user from '@/mixins/user.js'

// components
import List from '@/components/threads/List.vue'

export default {
  props: ['semail', 'suid'],

  mixins: [firestore, ui, user],

  data () {
    return {
      dialog: false,
      threads: null,
      text: '',
      title: ''
    }
  },

  computed: {
    /*
     *
     */
    canSend () {
      return this.text.length > 10
        && this.title.length > 10
    }
  },

  methods: {
    /*
     * Create a new thread and send message to user
     */
    async send () {
      // validate display name
      if (!this.displayName) {
        this.throwError('Your account must have a display name')

        return
      }

      // generate a timestamp
      var dt = new Date()

      // generate thread payload
      var doc1 = {
        createdOn: dt,
        instructor: this.uid,
        lastUpdated: dt,
        priority: 'low',
        read: false,
        status: 'closed',
        student: this.suid,
        studentEmail: this.semail,
        title: this.title
      }

      var thread = await this.fsCreate('threads', doc1)

      // validation
      if (!thread || !thread.id) {
        return
      }

      // generate message payload
      var doc2 = {
        displayName: this.displayName,
        read: null,
        sanitized: false,
        text: this.text,
        threadId: thread.id,
        timestamp: new Date(),
        uid: this.uid
      }

      var message = await this.fsCreate('messages', doc2)

      if (message) {
        // reset
        this.text = ''
        this.title = ''
        this.dialog = false
      }
    }
  },

  components: {
    List
  },

  /*
   * Firestore Connector
   */
  firestore () {
    return {
      threads: firebase.firestore()
        .collection('threads')
        .where('student', '==', this.suid)
    }
  }
}
</script>

<style>

</style>