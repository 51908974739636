<template>
  <div class="mt-50 mx-auto width-300">    
    <v-card>
      <v-card-text class="mb-0 pb-0">
        <div class="align-center white--text title">
          Administration Interface
        </div>
      </v-card-text>

      <div class="login-buttons">
        <div class="w100">
          <v-btn @click="signinWithGoogle" class="w100" color="orange darken-3 white--text">
            Sign in with Google
          </v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import firebase from 'firebase'

// mixins
import regex from '@/mixins/regex.js'

export default {
  mixins: [regex],

  data () {
    return {
      form: false,
      email: '',
      loading: false,
      password: '',
      errorMessage: null
    }
  },

  methods: {
    /*
     * Authentication using Google account
     */
    signinWithGoogle () {
      var provider = new firebase.auth.GoogleAuthProvider()

      firebase.auth()
        .signInWithPopup(provider)
        .then(() => {
          this.$router.push('/dashboard')
        })
        .catch(error => {
          console.error(error)
        })
    }
  },
}
</script>

<style>
.login-buttons {
  padding: 10px;
}
</style>