<template>
  <div>
    <!-- Dialog to create a new license -->
    <v-dialog v-model="value" width="500">
      <v-card>
        <v-card-title>
          Create New License
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <!-- UID -->
            <v-text-field v-model="fields.uid"
              :rules="[rules.present]"
              color="green"
              filled
              label="UID"
            ></v-text-field>

            <!-- Email -->
            <v-text-field v-model="fields.email"
              :rules="[rules.email, rules.present]"
              color="green"
              filled
              label="Email Address"
            ></v-text-field>

            <!-- SKU -->
            <v-select v-model="fields.sku"
              :items="['enterprise', 'defense-industrial-base']"
              :rules="[rules.present]"
              color="green"
              filled
              label="SKU"
            ></v-select>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            @click="createLicense()"
            :loading="loading"
            color="green"
            x-small
            >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'

// mixins
import firestore from '@/mixins/firestore.js'
import regex from '@/mixins/regex.js'

export default {
  props: ['orgId', 'value'],

  mixins: [
    firestore,
    regex
  ],

  data () {
    return {
      fields: {
        email: null,
        orgId: this.orgId,
        sku: null,
        uid: null
      }
    }
  },

  computed: {
    /*
     *
     */
    fieldModelValue: {
      get () {
        return this.value
      },
      
      set (newValue) {
        this.$emit('input', newValue)
      }
    },

    /*
     *
     */
    oneYearFromNow () {
      return moment(new Date()).add(1, 'year').toDate()
    }
  },

  methods: {
    /*
     * Create a new license in Firestore
     */
     createLicense: async function () {
      // validation
      if(!this.$refs.form.validate()) {
        return
      }

      // fixing the object
      this.fields.assignedOn = new Date()
      this.fields.expiry = this.oneYearFromNow

      //
      this.loading = true

      // firestore create
      var res = await this.fsCreate('licenses', this.fields)

      //
      this.loading = false

      // reset the object
      if (res) {
        this.fields.email = null
        this.fields.sku = null
        this.fields.uid = null

        // close dialog
        this.value = false
      }
    }
  },

  watch: {
    /*
     *
     */
    value (newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>

<style>

</style>