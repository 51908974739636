import firebase from 'firebase'

// stores
import ui from '@/stores/ui.js'

// mixin
export default {
  data () {
    return {
      db: firebase.firestore(),
      loading: false
    }
  },

  methods: {
    /*
     * Create
     */
    async fsCreate (collection, doc, message = null) {
      this.loading = true

      var res = await this.db
        .collection(collection)
        .add(doc)
        .then((docRef) => {
          if (message) {
            ui.commit('triggerSuccessSnackbar', message)
          } else {
            ui.commit('triggerSuccessSnackbar', 'Created!')
          }

          return docRef
        })
        .catch((error) => {
          window.console.error(error)
          ui.commit('triggerErrorSnackbar', error)

          return false
        })

      this.loading = false

      return res
    },

    /*
     * Delete
     */
    async fsDelete (collection, docId) {
      this.loading = true

      var res = await this.db
        .collection(collection)
        .doc(docId)
        .delete()
        .then(() => {
          ui.commit('triggerSuccessSnackbar', 'Deleted!')

          return true
        })
        .catch((error) => {
          window.console.error(error)
          ui.commit('triggerErrorSnackbar', error)

          return false
        })

      this.loading = false

      return res
    },

    /*
     * Get
     */
    async fsGet (collection, docId, quiet = false) {
      this.loading = true

      var res = await this.db
        .collection(collection)
        .doc(docId)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            if (!quiet) {
              ui.commit('triggerErrorSnackbar', 'The document requested does not exist')
            }

            return null
          }

          return doc.data()
        })
        .catch((error) => {
          window.console.error(error)

          if (!quiet) {
            ui.commit('triggerErrorSnackbar', error)
          }

          return null
        })
      
      this.loading = false

      return res
    },

    /*
     * Search for documents
     */
    async fsSearch (collection, key, condition, value, quiet = false) {
      this.loading = true

      var res = await this.db
        .collection(collection)
        .where(key, condition, value)
        .get()
        .then(querySnapshot => {
          if (!querySnapshot.size) {
            if (!quiet) {
              ui.commit('triggerErrorSnackbar', 'No documents found')
            }

            return []
          }

          // process the search results
          var objects = []

          for (var i = 0; i < querySnapshot.size; i++) {
            var doc = querySnapshot.docs[i]
            var obj = Object.assign({}, doc.data())

            // retrieve the id
            obj.id = doc.id

            // save the obj
            objects.push(obj)
          }

          return objects
        })
        .catch(error => {
          window.console.error(error)

          if (!quiet) {
            ui.commit('triggerErrorSnackbar', error)
          }

          return []
        })
      
      this.loading = false

      return res
    },

    /*
     * Get all documents in a collection or subcollection
     */
    async getCollectionDataByRef (ref) {
      var items = []

      await firebase.firestore()
        .collection(ref)
        .get()
        .then(snap => {
          snap.docs.map(doc => {
            items.push(doc.data())
          })
        })
        .catch(err => {
          window.console.error(err)
        })

      return items
    },

    /*
     * Set
     */
    async fsSet (collection, docId, doc, quiet = false) {
      this.loading = true

      var res = await this.db
        .collection(collection)
        .doc(docId)
        .set(doc)
        .then(() => {
          if (!quiet) {
            ui.commit('triggerSuccessSnackbar', 'Created!')
          }

          return true
        })
        .catch((error) => {
          window.console.error(error)
          ui.commit('triggerErrorSnackbar', error)

          return false
        })

      this.loading = false

      return res
    },

    /*
     * Set (merge)
     */
    async fsSetMerge (collection, docId, doc, message = 'Updated!') {
      this.loading = true

      var res = await this.db
        .collection(collection)
        .doc(docId)
        .set(doc, { merge: true })
        .then(() => {
          if (message) {
            ui.commit('triggerSuccessSnackbar', message)
          }

          return true
        })
        .catch((error) => {
          window.console.error(error)
          
          ui.commit('triggerErrorSnackbar', error)

          return false
        })

      this.loading = false

      return res
    },

    /*
     * Update
     */
    async fsUpdate (collection, docId, doc, quiet = false) {
      this.loading = true

      var res = await this.db
        .collection(collection)
        .doc(docId)
        .update(doc)
        .then(() => {
          if (!quiet) {
            ui.commit('triggerSuccessSnackbar', 'Updated!')
          }

          return true
        })
        .catch((error) => {
          window.console.error(error)
          ui.commit('triggerErrorSnackbar', error)

          return false
        })

      this.loading = false

      return res
    },

    /*
     * Prepares a new firestore object for updating/creating
     */
    newFsObject (original, newValues) {
      var doc = {}

      Object.keys(original).forEach(k => {
        if (Object.keys(newValues).includes(k)) {
          doc[k] = newValues[k]
        }
      })

      return doc
    },

    /*
     * Safely copies the keys from one object into another object
     * only if the key of the first object has the key of the second
     * object 
     */
    safeCopyObjects (from, to) {
      Object.keys(from).forEach(k => {
        if (Object.keys(to).includes(k)) {
          to[k] = from[k]
        }
      })
    }
  }
}
