<template>
  <v-card class="fill-height pd-10" outlined>
    <v-card-title>
      <div class="text-center w100">
        Total Active Users
      </div>
    </v-card-title>

    <v-card-text class="bold fs-40 mb-15 mt-15 text-center w100">
      {{ n }}
    </v-card-text>
  </v-card>
</template>

<script>
// mixins
import metrics from '@/mixins/metrics.js'
import ui from '@/mixins/ui.js'

export default {
  mixins: [metrics, ui],

  computed: {
    /*
     *
     */
    n () {
      if (!this.platformPerformance) {
        return 0
      }

      return this.platformPerformance['active-students'] || 0
    }
  }
}
</script>

<style>

</style>