<template>
  <div class="mt-20 fs-16">
    <!-- Overview -->
    <div class="title">
      Overview
    </div>

    <v-layout class="m-0" fill-height row>
      <!-- Overview -->
      <v-flex class="pd-10" md4>
        <OverviewTable :scores="scores" />
      </v-flex>

      <!-- Products -->
      <v-flex class="pd-10" md4>
        <ProductsTable :products="products" />
      </v-flex>

      <!-- Roles -->
      <v-flex class="pd-10" md4>
        <RolesTable :roles="roles" />
      </v-flex>
    </v-layout>

    <!-- Tags -->
    <div class="mt-50 title">
      Tags
    </div>

    <Tags v-model="tags" :uid="uid" />

    <!-- CPD -->
    <div class="mt-50 title">
      Continued Professional Development
    </div>

    <CPD :data="scores" />

    <!-- Competencies -->
    <div class="mt-50 title">
      Competencies
    </div>

    <Competencies :scores="scores" />
  </div>
</template>

<script>
// components
import Competencies from '@/components/student/data/Competencies'
import CPD from '@/components/student/data/CPD'
import OverviewTable from '@/components/student/data/OverviewTable'
import ProductsTable from '@/components/student/data/ProductsTable'
import RolesTable from '@/components/student/data/RolesTable'
import Tags from '@/components/student/data/Tags'

export default {
  props: ['scores', 'products', 'roles', 'tags', 'uid'],

  components: {
    Competencies,
    CPD,
    OverviewTable,
    ProductsTable,
    RolesTable,
    Tags
  }
}
</script>

<style>

</style>