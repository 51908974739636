<template>
  <v-navigation-drawer v-model="menu" app :mini-variant.sync="mini">
    <div class="logo">
      MCSI
    </div>

    <!-- Web Menu -->
    <v-list class="hidden-md-and-down" dense nav>
      <v-list-item v-for="(item, index) in filter(items)" :key="index" :ripple="false" :to="item.path">
        <v-list-item-action>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" :color="item.color">
                {{ item.icon }}
              </v-icon>
            </template>

            <span>
              {{ item.text }}
            </span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <!-- Mobile Menu -->
    <v-list class="hidden-lg-and-up mobile-menu" dense nav>
      <v-list-item v-for="(item, index) in filter(items)" :key="index" :to="item.path">
        <v-list-item-content>
          <v-list-item-title color="grey--text">
            {{ item.text }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// mixins
import menu from '@/mixins/menu.js'
import platform from '@/mixins/platform.js'
import user from '@/mixins/user.js'

// component
export default {
  mixins: [menu, platform, user],

  computed: {
    /*
     *
     */
    items () {
      return [
        {
          icon: 'mdi-home',
          path: '/dashboard',
          text: 'Dashboard'
        },

        {
          condition: this.permissions.includes('sales'),
          icon: 'mdi-google-analytics',
          path: '/metrics',
          text: 'Metrics'
        },

        {
          condition: this.permissions.includes('sales'),
          icon: 'mdi-tag-multiple',
          path: '/sales',
          text: 'Orders'
        },

        {
          condition: this.roles.includes('instructor'),
          icon: 'mdi-account-school',
          path: '/students',
          text: 'Students'
        },

        {
          color: (this.threads.length) ? 'red' : undefined,
          condition: this.roles.includes('instructor'),
          icon: 'mdi-forum',
          path: '/messages',
          text: 'Messages'
        },

        {
          icon: 'mdi-alert',
          path: '/audits',
          text: 'Audit Logs'
        },

        {
          icon: 'mdi-face-agent',
          path: '/agents',
          text: 'Agents'
        },

        {
          icon: 'mdi-logout',
          path: '/logout',
          text: 'Logout'
        }
      ]
    },

    /*
     *
     */
    mini () {
      return !this.mobile
    }
  },

  methods: {
    /*
     *
     */
    display (item) {
      return item.condition === undefined || item.condition === true
    },

    /*
     *
     */
    filter (items) {
      return items.filter(i => this.display(i))
    }
  }
}
</script>

<style scoped>
.v-icon {
  color: #6C6E7B;
}

.v-list-item {
  padding-left: 18px !important;
}

.v-list-item--link:before {
  background-color: unset;
}

.theme--dark.v-list .v-list-item--active .v-icon {
  color: #D0AE7B;
}

.theme--light.v-list .v-list-item--active .v-icon {
  color: #62BA2D;
}
</style>
