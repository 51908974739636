<template>
  <div>
    <!-- Loading Bar -->
    <v-progress-linear v-if="!items" color="green" indeterminate></v-progress-linear>

    <div v-else>
      <v-data-table
        :headers="headers"
        :items="items"
      ></v-data-table>
    </div>
  </div>
</template>

<script>
// mixins
import platform from '@/mixins/platform.js'
import ui from '@/mixins/ui.js'

export default {
  props: ['bookmarks'],
  mixins: [platform, ui],

  data () {
    return {
      headers: [
        {
          sortable: false,
          text: 'Exercise',
          value: 'title'
        },

        {
          sortable: false,
          text: 'Category',
          value: 'category'
        }
      ]
    }
  },

  computed: {
    /*
     *
     */
    items () {
      if (!Array.isArray(this.bookmarks)) {
        return []
      }

      var res = []

      for (var i = 0; i < this.bookmarks.length; i++) {
        var uuid = this.bookmarks[i]
        var exercise = this.getExercise(uuid)

        if (exercise) {
          res.push({
            category: this.formatText(exercise.groupUID),
            title: exercise.title
          })
        }
      }

      return res
    }
  }
}
</script>

<style>

</style>