<template>
  <v-card hover class="fill-height pd-10" outlined to="/students/search/browsed-shopping-cart">
    <v-card-title>
      <div class="text-center w100">
        Closing Opportunities
      </div>
    </v-card-title>

    <v-card-text class="bold fs-50 mb-15 mt-15 text-center w100">
      <div class="green--text">
        {{ filtered }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase'

// mixins
import time from '@/mixins/time.js'
import utils from '@/mixins/utils.js'

export default {
  mixins: [time, utils],

  data () {
    return {
      items: []
    }
  },

  computed: {
    /*
     *
     */
     date () {
      return this.dateKey.subtract(7, 'd').toDate()
    },

    /*
     *
     */
    filtered () {
      return this.items
        .filter(i => i.text && i.text.includes('Added'))
        .map(i => i.email)
        .filter(this.onlyUnique)
        .length
    }
  },

  /*
  * Firestore Connector
  */
  firestore () {
    return {
      items: firebase.firestore()
        .collection('activity-logs')
        .where('sanitized', '==', true)
        .where('type', '==', 'sales')
        .where('timestamp', '>', this.date)
    }
  }
}
</script>

<style>

</style>