import Dashboard from '@/views/Dashboard'
import Organization from '@/views/Organization'

export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },

  {
    path: '/organization/:orgId',
    name: 'organization',
    component: Organization
  }
]