<template>
  <v-card class="fill-height pd-10 unanswered-questions" outlined>
    <v-card-title>
      <div class="text-center w100">
        Unanswered Submissions
      </div>
    </v-card-title>

    <v-card-text class="bold fs-50 mb-15 mt-15 text-center w100">
      <div :class="dataCss(submissions.length, 1)">
        {{ submissions.length }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase'

// mixins
import ui from '@/mixins/ui.js'

export default {
  mixins: [ui],

  data () {
    return {
      submissions: []
    }
  },

  /*
   * Firestore Connector
   */
  firestore () {
    return {
      submissions: firebase.firestore()
        .collection('submissions')
        .where('pass', '==', null)
    }
  }
}
</script>

<style>
</style>