import moment from 'moment'

export default {
  computed: {
    /*
     *
     */
    dateKey () {
      return moment.tz(new Date(), 'Australia/Victoria')
    },

    /*
     *
     */
    dayKey () {
      return this.dateKey.startOf('day').format('YYYY-MM-DD')
    },

    /*
     *
     */
    monthKey () {
      return this.dateKey.startOf('month').format('YYYY-MM-DD')
    },

    /*
     *
     */
    yearKey () {
      return this.dateKey.startOf('isoWeek').format('YYYY-MM-DD')
    }
  },

  methods: {
    /*
     *
     */
    AustralianTzDate (dateString) {
      return moment.tz(new Date(dateString), 'Australia/Victoria').startOf('day').toDate()
    }
  }
}