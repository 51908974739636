<template>
  <div>
    <!-- Message Card -->
    <v-card class="mb-10 mt-10" flat outlined>
      <v-card-text>
        <div>
          <!-- Display Name and Link to Portfolio -->
          <router-link class="bold no-decoration" :to="`/student/${message.uid}`">
            {{ message.displayName || 'Anonymous' }}
          </router-link>

          <!-- Timestamp -->
          <span>
            {{ ago(message.timestamp) }} ago
          </span>

          <!-- Edit -->
          <span v-if="canEdit && last">
            (<a @click="text = message.text; dialog = true;" class="no-decoration">
              Edit
            </a>)
          </span>

          <!-- Read on -->
          <v-chip v-if="message.read" style="float: right;" x-small>
            Read on: {{ formatDate(message.read.toDate()) }}
          </v-chip>
        </div>

        <pre class="message fs-15 mt-2" v-html="message.text"></pre>
      </v-card-text>
    </v-card>

    <!-- Edit Dialog -->
    <v-dialog v-model="dialog" width="800">
      <v-card>
        <v-card-title>
          Edit Message
        </v-card-title>

        <v-divider />

        <v-card-text class="mb-0 mt-3">
          <v-textarea v-model="text" color="green" height="500" filled></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            @click="edit()"
            :loading="loading"
            color="green"
            small
            >

            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// mixins
import firestore from '@/mixins/firestore.js'
import ui from '@/mixins/ui.js'
import user from '@/mixins/user.js'

export default {
  props: ['last', 'message'],

  mixins: [firestore, ui, user],

  data () {
    return {
      dialog: false,
      text: ''
    }
  },

  computed: {
    /*
     * Returns TRUE if the instructor can edit this comment
     */
    canEdit () {
      // get current date
      var dt = this.message.timestamp.toDate()

      // detect one hour in the future
      dt.setTime(dt.getTime() + (60*60*1000))

      // validate that the instructor can edit
      return this.uid === this.message.uid
        && new Date() < dt
    }
  },

  methods: {
    /*
     *
     */
    async edit () {
      var doc = {
        text: this.text
      }

      if (await this.fsUpdate('messages', this.message.id, doc)) {
        // close dialog
        this.dialog = false
      }
    }
  }
}
</script>

<style>

</style>