<template>
  <div class="view">
    <!-- Loading Bar -->
    <v-progress-linear v-if="!submissions" color="green" indeterminate></v-progress-linear>

    <div v-else>
      <!-- Title -->
      <div class="heading-1">
        Formidable Solutions
      </div>

      <!-- Table -->
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        >
        <!-- Title -->
        <template v-slot:item.title="{ item }">
          <a @click="openSubmissionDialog(item.id)">
            {{ item.title }}
          </a>
        </template>

        <!-- Trophy -->
        <template v-slot:item.trophy="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on"
                :color="(item.trophy === 'gold') ? 'yellow darken-4' : 'white'"
                class="ml-1"
                x-small
                >
                mdi-trophy
              </v-icon>
            </template>

            <span>
              {{ item.trophy }}
            </span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase'

// mixins
import firestore from '@/mixins/firestore.js'
import platform from '@/mixins/platform.js'
import ui from '@/mixins/ui.js'

export default {
  mixins: [firestore, platform, ui],

  data () {
    return {
      headers: [
        {
          sortable: false,
          text: 'Exercise',
          value: 'title'
        },

        {
          sortable: false,
          text: 'Trophy',
          value: 'trophy'
        },

        {
          sortable: false,
          text: 'Date',
          value: 'date'
        }
      ],
      
      submissions: null
    }
  },

  computed: {
    /*
     *
     */
    items () {
      if (!this.submissions) {
        return []
      }

      var res = []

      for (var i = 0; i < this.submissions.length; i++) {
        var submission = this.submissions[i]

        var doc = {
          date: this.formatDate(submission.timestamp.toDate()),
          id: submission.id,
          title: this.getExerciseTitle(submission.exercise),
          trophy: submission.assessment.trophy || null
        }

        res.push(doc)
      }

      return res
    }
  },

  /*
   * Firestore Connector
   */
  firestore () {
    return {
      submissions: firebase.firestore()
        .collection('submissions')
        .where('assessment.trophy', 'in', ['gold', 'platinum'])
        .orderBy('timestamp', 'desc')
    }
  }
}
</script>

<style>

</style>