// Activity
import ActiveMonth from '@/views/students/active/Month'
import ActiveToday from '@/views/students/active/Today'
import ActiveWeek from '@/views/students/active/Week'
import HallOfFame from '@/views/students/active/HallOfFame'

// Search
import BrowsedShoppingCart from '@/views/students/search/BrowsedShoppingCart'
import ByProduct from '@/views/students/search/ByProduct'
import ByOrganization from '@/views/students/search/ByOrganization'

// Search by number of exercises completed
import OneExercise from '@/views/students/search/1Exercise'
import TwoExercises from '@/views/students/search/2Exercises'
import FiveExercises from '@/views/students/search/5Exercises'

// Main pages
import Index from '@/views/students/Index'
import Student from '@/views/students/Student'

export default [
  {
    path: '/students',
    name: 'students-index',
    component: Index
  },

  {
    path: '/students/active/month',
    name: 'students-active-month',
    component: ActiveMonth
  },

  {
    path: '/students/active/today',
    name: 'students-active-today',
    component: ActiveToday
  },

  {
    path: '/students/active/week',
    name: 'students-active-week',
    component: ActiveWeek
  },

  {
    path: '/students/active/hall-of-fame',
    name: 'students-active-hall-of-fame',
    component: HallOfFame
  },

  {
    path: '/students/search/by-organization',
    name: 'students-search-by-organization',
    component: ByOrganization
  },

  {
    path: '/students/search/browsed-shopping-cart',
    name: 'students-browsed-shopping-cart',
    component: BrowsedShoppingCart
  },

  {
    path: '/students/search/by-product',
    name: 'students-search-by-product',
    component: ByProduct
  },

  {
    path: '/students/search/1-exercise',
    name: 'students-search-one-exercise',
    component: OneExercise
  },

  {
    path: '/students/search/2-exercises',
    name: 'students-search-two-exercises',
    component: TwoExercises
  },

  {
    path: '/students/search/5-exercises',
    name: 'students-search-five-exercises',
    component: FiveExercises
  },

  {
    path: '/student/:uid',
    name: 'student-profile',
    component: Student
  }
]