<template>
  <div class="view width-840">
    <div class="heading-1">
      This Month's Most Active Students
    </div>

    <MostActiveTable :date="date" type="user-engagement-monthly-analytics" />
  </div>
</template>

<script>
// mixins
import time from '@/mixins/time.js'

// components
import MostActiveTable from '@/components/students/MostActiveTable.vue'

export default {
  mixins: [time],

  computed: {
    /*
     *
     */
    date () {
      return this.dateKey.startOf('month').toDate()
    }
  },

  components: {
    MostActiveTable
  }
}
</script>

<style>

</style>