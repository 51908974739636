<template>
  <div class="view width-840">
    <div class="heading-1">
      Sales
    </div>

    <div class="index-menu">
      <div class="title">
        Select a page
      </div>

      <IndexMenu :items="items" />
    </div>
  </div>
</template>

<script>
// components
import IndexMenu from '@/components/IndexMenu'

// view
export default {
  computed: {
    items () {
      return [
        {
          icon: 'mdi-currency-usd',
          path: '/sales/orders',
          text: 'Orders'
        },

        {
          icon: 'mdi-currency-usd',
          path: '/sales/subscriptions/cancelled',
          text: 'Subscription: Cancelled'
        }
      ]
    }
  },

  components: {
    IndexMenu
  }
}
</script>

<style>

</style>