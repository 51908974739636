<template>
  <div class="view">
    <v-card>
      <v-card-title>
        <div class="text-center w100">
          Instructor Hall of Fame
        </div>
      </v-card-title>

      <!-- Loading bar -->
      <v-progress-linear v-if="!loaded" color="green" :indeterminate="true"></v-progress-linear>

      <div v-else>
        <!-- Table -->
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="-1"
          class="elevation-1"
          disable-pagination
          hide-default-footer
          >
          <!-- Review Points -->
          <template v-slot:item.reviewedExercises="{ item }">
            {{ item.reviewedExercises || 0 }}
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script>
import firebase from 'firebase'

// mixins
import platform from '@/mixins/platform.js'

// component
export default {
  mixins: [platform],

  data () {
    return {
      docs: null,
      items: [],

      headers: [
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Nb. Exercises Reviewed', value: 'reviewedExercises', sortable: false }
      ]
    }
  },

  computed: {
    /*
     * Returns TRUE of the data has loaded
     */
    loaded () {
      return Array.isArray(this.items) && this.items.length > 0
    }
  },

  methods: {
    /*
     *
     */
    getItems () {
      // validation
      if (!Array.isArray(this.docs)) {
        return
      }

      for (var i = 0; i < this.docs.length; i++) {
        // key variable
        var doc   = this.docs[i]
        var uid   = doc.id

        // retrieve the email
        var email = this.getInstructorEmail(uid)

        if (email) {
          this.items.push({
            email: email,
            reviewedExercises: doc.reviewedExercises
          })
        }
      }
    }
  },

  /*
   *
   */
  created () {
    var query = firebase.firestore()
      .collection('scores')
      .orderBy('reviewedExercises', 'desc')

    this.$bind('docs', query)
      .then(() => {
        this.getItems()
      })
  }
}
</script>

<style>

</style>