<template>
  <div class="view">
    <!-- Loading Bar -->
    <v-progress-linear v-if="!submissions" color="green" indeterminate></v-progress-linear>

    <div v-else>
      <!-- Title -->
      <div class="heading-1">
        Reviewed Exercises: Today
      </div>

      <!-- Table -->
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        >
        <!-- Title -->
        <template v-slot:item.title="{ item, index }">
          {{ item.title }}

          <v-icon @click="showFeedback(index)" v-if="item.feedback" color="green" small>
            mdi-message-processing-outline
          </v-icon>
        </template>

        <!-- Status -->
        <template v-slot:item.status="{ item }">
          <v-icon v-if="item.status" color="green">
            mdi-check
          </v-icon>

          <v-icon v-else-if="item.status === false" color="red">
            mdi-close
          </v-icon>
          
          <v-icon v-else color="orange">
            mdi-timer-sand-empty
          </v-icon>
        </template>
      </v-data-table>
    </div>

    <v-dialog v-model="dialog" width="800">
      <v-card>
        <v-card-title>
          Instructor Feedback
        </v-card-title>

        <v-divider />

        <v-card-text class="mb-0 mt-3">
          <p class="fs-13">
            Submission Id: {{ submissionId }} | Instructor: {{ instructor }}
          </p>

          <v-textarea v-model="feedback" height="500" filled></v-textarea>

          <v-btn @click="dialog = false;" small>
            Close
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase'

// mixins
import platform from '@/mixins/platform.js'
import time from '@/mixins/time.js'
import ui from '@/mixins/ui.js'

export default {
  mixins: [platform, time, ui],

  data () {
    return {
      dialog: false,
      feedback: null,

      headers: [
        {
          sortable: false,
          text: 'Exercise',
          value: 'title'
        },

        {
          sortable: false,
          text: 'Status',
          value: 'status'
        },

        {
          sortable: false,
          text: 'Date',
          value: 'date'
        },

        {
          sortable: false,
          text: 'Instructor',
          value: 'instructorEmail'
        }
      ],

      instructor: null,
      submissionId: null,
      submissions: null
    }
  },

  computed: {
    /*
     *
     */
    key () {
      return this.AustralianTzDate(this.dayKey)
    },

    /*
     *
     */
    items () {
      if (!this.submissions) {
        return []
      }

      var res = []

      for (var i = 0; i < this.submissions.length; i++) {
        var submission = this.submissions[i]

        var feedback = (submission.assessment && submission.assessment.comments) ? submission.assessment.comments : null

        var doc = {
          date: this.formatDate(submission.timestamp.toDate()),
          feedback: feedback,
          id: submission.id,
          instructor: submission.reviewer,
          instructorEmail: this.getInstructorEmail(submission.reviewer),
          status: submission.pass,
          title: this.getExerciseTitle(submission.exercise),
          uuid: submission.exercise
        }

        res.push(doc)
      }

      return res
    }
  },

  methods: {
    /*
     *
     */
    showFeedback (i) {
      // data reset just in case
      this.dialog = true
      this.feedback = null
      this.instructor = null
      this.submissionId = null

      // retrieve submission
      var submission = this.items[i]

      // show feedback in dialog
      this.feedback = submission.feedback
      this.instructor = this.getInstructorEmail(submission.instructor)
      this.submissionId = submission.id
      this.dialog = true
    }
  },

  /*
   * Firestore Connector
   */
  firestore () {
    return {
      submissions: firebase.firestore()
        .collection('submissions')
        .where('reviewOn', '>', this.key)
    }
  }
}
</script>

<style>

</style>