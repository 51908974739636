import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// routes
import agentsRoutes from './agents.js'
import alertsRoutes from './alerts.js'
import messagesRoutes from './messages.js'
import metricsRoutes from './metrics.js'
import platformRoutes from './platform.js'
import publicRoutes from './public.js'
import salesRoutes from './sales.js'
import searchRoutes from './search.js'
import studentsRoutes from './students.js'

var routes = []
  .concat(agentsRoutes)
  .concat(alertsRoutes)
  .concat(messagesRoutes)
  .concat(metricsRoutes)
  .concat(platformRoutes)
  .concat(publicRoutes)
  .concat(salesRoutes)
  .concat(searchRoutes)
  .concat(studentsRoutes)

export default new Router({
  routes: routes,

  /*
   * Scroll to top with every route change
   */
  scrollBehavior () {
    var element = window.document.getElementById('platform')

    if (element) {
      element.scrollIntoView()
    }
  }
})