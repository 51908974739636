<template>
  <div>
    <!-- Dialog to create a new license -->
    <v-dialog v-model="value" width="500">
      <v-card>
        <v-card-title>
          Edit License
        </v-card-title>

        <!-- Loading bar -->
        <v-progress-linear v-if="!loaded" color="green" :indeterminate="true"></v-progress-linear>

        <div v-else>
          <v-card-text>
            <v-form ref="form">
              <!-- UID -->
              <v-text-field v-model="fields.uid"
                :rules="[rules.present]"
                color="green"
                filled
                label="UID"
              ></v-text-field>

              <!-- Email -->
              <v-text-field v-model="fields.email"
                :rules="[rules.email, rules.present]"
                color="green"
                filled
                label="Email Address"
              ></v-text-field>

              <!-- SKU -->
              <v-select v-model="fields.sku"
                :items="['enterprise', 'defense-industrial-base']"
                :rules="[rules.present]"
                color="green"
                filled
                label="SKU"
              ></v-select>
            </v-form>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              @click="editLicense()"
              :loading="loading"
              color="green"
              x-small
              >
              Edit
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// mixins
import firestore from '@/mixins/firestore.js'
import regex from '@/mixins/regex.js'

export default {
  props: ['licenseId', 'value'],

  mixins: [
    firestore,
    regex
  ],

  data () {
    return {
      fields: {
        email: null,
        sku: null,
        uid: null
      }
    }
  },

  computed: {
    /*
     *
     */
    fieldModelValue: {
      get () {
        return this.value
      },
      
      set (newValue) {
        this.$emit('input', newValue)
      }
    },

    /*
     * Returns TRUE if the data is loaded
     */
    loaded () {
      return this.fields && this.fields.email
    }
  },

  methods: {
    /*
     * Edit an existing license in Firestore
     */
     editLicense: async function () {
      // validation
      if(!this.$refs.form.validate()) {
        return
      }

      //
      this.loading = true

      // firestore update
      var res = this.fsUpdate('licenses', this.licenseId, this.fields)

      //
      this.loading = false

      // reset the object
      if (res) {
        this.reset()

        //
        this.licenseId = null

        // close dialog
        this.value = false
      }
    },

    /*
     *
     */
    reset () {
      // reset the fields
      this.fields.email = null
      this.fields.sku = null
      this.fields.uid = null
    }
  },

  watch: {
    /*
     *
     */
    value: async function (newValue) {
      this.$emit('input', newValue)

      if (newValue) {
        // force reset just in case
        this.reset()

        //
        var data = await this.fsGet('licenses', this.licenseId)

        if (data) {
          this.fields.email = data.email
          this.fields.sku = data.sku
          this.fields.uid = data.uid
        }
      }
    }
  }
}
</script>

<style>

</style>