<template>
  <v-card>
    <v-card-title>
      Sales
    </v-card-title>

    <v-divider />

    <v-card-text>
      <GChart
        :data="chartData"
        :options="chartOptions"
        class="google-chart"
        type="LineChart"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment'
import { GChart } from 'vue-google-charts/legacy'

export default {
  props: ['format', 'items'],

  computed: {
    /*
     *
     */
    chartData () {
      var res = [['Month', 'Sales', 'Refunds']]
      var months = {}

      // process the items
      if (this.items) {
        for (var i = 0; i < this.items.length; i++) {
          // retrieve document
          var doc = this.items[i]

          // validation
          if (!doc.content || !doc.eventName || !doc.createdOn) {
            continue
          }

          // only live data
          if (doc.mode !== 'Live') {
            continue
          }

          // filtering
          if (!['order.completed', 'order.refund.created'].includes(doc.eventName)) {
            continue
          }

          // retrieve the date
          var dt = new Date(doc.createdOn)

          // retrieve the month
          var key = moment.tz(dt, 'Australia/Victoria').format(this.format)

          // create the data structure
          if (!months[key]) {
            months[key] = {
              orders: 0,
              refunds: 0,
              sales: 0
            }
          }

          // add the values
          if (doc.eventName === 'order.completed') {
            months[key].orders += 1
            months[key].sales += doc.content.total
          } else {
            months[key].orders -= 1
            months[key].refunds += doc.content.amount
            months[key].sales -= doc.content.amount
          }
        }
      }

      // populate the results into the right format
      var keys = Object.keys(months).sort()

      for (var j = 0; j < keys.length; j++) {
        var key2 = keys[j]
        var month = months[key2]

        res.push([key2, month.sales, month.refunds])
      }

      return res
    },

    /*
     *
     */
    chartOptions () {
      return {
        height: 300,

        backgroundColor: { fill: 'transparent' },

        colors: ['#59C611', '#B71C1C'],

        legend: {
          textStyle: {
            color: '#FFF'
          }
        },

        hAxis: {
          textStyle: {
            color: '#FFF'
          }
        },

        vAxis: {
          textStyle: {
            color: '#FFF'
          }
        }
      }
    }
  },

  components: {
    GChart
  }
}
</script>

<style>

</style>