<template>
  <div class="view width-840">
    <!-- Loading bar -->
    <v-progress-linear v-if="!loaded" color="green" :indeterminate="true"></v-progress-linear>

    <!-- View -->
    <div v-else>
      <div class="heading-1">
        Enterprise Users
      </div>

      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        >
        <!-- Email -->
        <template v-slot:item.email="{ item, index }">
          <v-chip
            :color="color(index)"
            class="fs-13"
            small
            >
            {{ item.email }}
          </v-chip>
        </template>

        <!-- Joined On -->
        <template v-slot:item.joinedOn="{ item }">
          <span v-if="item['joined-enterprise-on']">
            {{ formatDate(item['joined-enterprise-on'].toDate()) }}
          </span>

          <span v-else>
            Unknown
          </span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase'

// mixins
import ui from '@/mixins/ui.js'

export default {
  mixins: [ui],

  data () {
    return {
      headers: [
        {
          sortable: false,
          text: 'Email',
          value: 'email'
        },

        {
          sortable: false,
          text: 'UID',
          value: 'id'
        },

        {
          sortable: false,
          text: 'Joined On',
          value: 'joinedOn'
        }
      ],

      items: null
    }
  },

  computed: {
    /*
     *
     */
    loaded () {
      return this.items && this.items.length
    }
  },

  methods: {
    /*
     *
     */
    color (index) {
      var item = this.items[index]

      if (!item.email) {
        return 'primary'
      }

      if (item.email.includes('@defence.gov.au')) {
        return 'blue'
      }

      return 'red'
    }
  },

  /*
   * Firestore Connector
   */
  firestore () {
    return {
      items: firebase.firestore()
        .collection('settings')
        .where('roles', 'array-contains', 'enterprise')
    }
  }
}
</script>

<style>

</style>