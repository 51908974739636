<template>
  <v-card class="pd-10" raised>
    <div v-for="(score, key, index) in scores.exercisesPoints" :key="index">
      <!-- Label -->
      <span class="fs-15 mt-2 white--text">
        <b>
          {{ formatText(key) }}:
        </b> 

        <span class="fs-13">
          {{ score }} 

            <span v-if="score > 1">
              pts
            </span>

            <span v-else>
              pt
            </span>
        </span>

        <v-progress-linear
          color="green"
          :value="score"
          height="5"
          style="margin: 5px 0px;"
        ></v-progress-linear>
      </span>
    </div>
  </v-card>
</template>

<script>
// mixins
import platform from '@/mixins/platform.js'
import ui from '@/mixins/ui.js'

export default {
  props: ['scores'],

  mixins: [platform, ui]
}
</script>

<style>

</style>