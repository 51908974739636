<template>
  <div>
    <GChart
      :data="chartData"
      :options="chartOptions"
      type="LineChart"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { GChart } from 'vue-google-charts/legacy'

// View
export default {
  props: ['data'],

  data () {
    return {
      chartOptions: {
        chartArea: {
          width: '100%'
        },
        backgroundColor: { fill: 'transparent' },

        colors: ['#59C611'],

        height: 250,

        legend: {
          position: 'none',
          textStyle: {
            color: 'white'
          }
        },

        vAxis: {
          minValue: 0
        },

        hAxis: {
          textStyle: {
            color: '#FFF'
          }
        }
      }
    }
  },

  computed: {
    /*
     *
     */
    chartData () {
      var res = [['Month', 'Your score']]
      
      console.log(this.data['monthly-scores'])

      this.months.forEach(m => {
        var points = 0

        if (this.data['monthly-scores'][m] !== undefined) {
          points = this.data['monthly-scores'][m]
        }

        res.push([m, points])
      })

      return res
    },

    /*
     * Generate the months
     */
    months () {
      const months = []
      const dateStart = moment.tz(new Date(), 'Australia/Victoria')
      const dateEnd = moment.tz(new Date(), 'Australia/Victoria').subtract(18, 'month')

      while (dateStart.diff(dateEnd, 'months') >= 0) {
        months.push(dateEnd.format('MMM-YY').toLocaleLowerCase())
        dateEnd.add(1, 'month')
      }

      return months
    }

  },

  components: {
    GChart
  }
}
</script>

<style>

</style>