<template>
  <div class="public-main">
    <v-content>
      <slot />
    </v-content>
  </div>
</template>

<script>
// css
import '@/assets/css/public-layout.css'

// view
export default {

}
</script>

<style>

</style>