<template>
  <v-card class="fill-height pd-10 unanswered-questions" hover outlined to="/messages">
    <v-card-title>
      <div class="text-center w100">
        Your Threads
      </div>
    </v-card-title>

    <v-card-text class="bold fs-50 mb-15 mt-15 text-center w100">
      <span :class="dataCss(threads.length, 1)">
        {{ myThreads.length }}
      </span>
      
      <span class="fs-16"> out of {{ threads.length }}</span>
    </v-card-text>
  </v-card>
</template>

<script>
// mixins
import platform from '@/mixins/platform.js'
import ui from '@/mixins/ui.js'
import user from '@/mixins/user.js'

export default {
  mixins: [
    platform,
    ui,
    user
  ],

  computed: {
    /*
     *
     */
    myThreads () {
      // validation
      if (!Array.isArray(this.threads)) {
        return []
      }

      return this.threads.filter(t => t.instructor && t.instructor === this.uid)
    }
  }
}
</script>

<style>

</style>