<template>
  <div class="view">
    <div class="heading-1">
      Agents
    </div>

    <v-data-table
      :headers="headers"
      :items="agents"
      class="elevation-1"
      hide-default-footer
      >
      <!-- Email -->
      <template v-slot:item.email="{ item }">
        <router-link :to="`/agent/${item.id}`" class="no-decoration">
          {{ item.email }}
        </router-link>
      </template>

      <!-- Instructor -->
      <template v-slot:item.instructor="{ item, index }">
        <span v-if="hasRole(index, 'instructor')">
          Yes
        </span>
      </template>

      <!-- Sales -->
      <template v-slot:item.sales="{ item, index }">
        <span v-if="hasPermissions(index, 'sales')">
          Yes
        </span>
      </template>

      <!-- Account Management -->
      <template v-slot:item.account_management="{ item, index }">
        <span v-if="hasPermissions(index, 'account-management')">
          Yes
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase'

export default {
  data () {
    return {
      agents: [],

      headers: [
        {
          sortable: false,
          text: 'Email',
          value: 'email'
        },

        {
          sortable: false,
          text: 'instructor',
          value: 'instructor'
        },

        {
          sortable: false,
          text: 'Sales',
          value: 'sales'
        },

        {
          sortable: false,
          text: 'Account Management',
          value: 'account_management'
        }
      ]
    }
  },

  methods: {
    /*
     *
     */
    hasPermissions (index, permission) {
      var agent = this.agents[index]

      if (!agent || !agent.permissions) {
        return false
      }

      return agent.permissions.includes(permission)
    },

    /*
     *
     */
    hasRole (index, role) {
      var agent = this.agents[index]

      if (!agent || !agent.roles) {
        return false
      }

      return agent.roles.includes(role)
    }
  },

  firestore () {
    return {
      agents: firebase.firestore()
        .collection('settings')
        .where('permissions', 'array-contains', 'admin-console')
    }
  }
}
</script>

<style>

</style>