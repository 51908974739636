<template>
  <div>
    <v-progress-linear v-if="!loaded" color="green" :indeterminate="true"></v-progress-linear>

    <div v-else>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="logs"
          dense
          >
          <!-- User -->
          <template v-slot:item.email="{ item }">
            {{ item.email.split('@')[0] }}
          </template>

          <!-- Timestamp -->
          <template v-slot:item.timestamp="{ item }">
            {{ formatDate(item.key) }}
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase'
import moment from 'moment'

export default {
  data () {
    return {
      headers: [
        {
          sortable: false,
          text: 'User',
          value: 'email'
        },

        {
          sortable: false,
          text: 'Action',
          value: 'text'
        },

        {
          sortable: false,
          text: 'Timestamp',
          value: 'timestamp'
        }
      ],

      logs: null
    }
  },

  computed: {
    /*
     * Returns TRUE if the logs are loaded
     */
    loaded () {
      return Array.isArray(this.logs) && this.logs.length > 0
    }
  },

  methods: {
    /* 
     *
     */
    formatDate (dt) {
      return moment(dt).format('MMM Do YY')
    }
  },
  
  /*
   * Firestore Connector
   */
  firestore () {
    return {
      logs: firebase.firestore()
        .collection('activity-logs')
        .where('type', '==', 'instructor-action')
        .where('sanitized', '==', true)
        .orderBy('timestamp', 'desc')
        .limit(200)
    }
  }
}
</script>

<style>

</style>