<template>
  <div>
    <!-- Instructor Feedback Dialog -->
    <v-dialog v-model="submissionDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
      >
      <v-card tile>
        <v-toolbar dense>
          <v-btn @click="closeSubmissionDialog()" icon small>
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-toolbar-title>
            Submission Id: {{ submissionDialogId }}
          </v-toolbar-title>
        </v-toolbar>

        <!-- Loading bar -->
        <v-progress-linear v-if="!loaded" color="green" indeterminate></v-progress-linear>

        <!-- Content -->
        <v-card-text v-else class="mb-0">
          <h2 class="mb-3 mt-20">
            {{ submission.title }}
          </h2>

          <v-divider class="mb-5" />

          <!-- Feedback -->
          <h3>Feedback</h3>

          <p class="fs-13">
            Instructor: {{ submission.instructor }}
          </p>

          <v-textarea v-model="submission.feedback" height="300" filled></v-textarea>

          <!-- YouTube -->
          <span v-if="canViewVideos && submission.youtube">
            <h3>YouTube</h3>

            <div @click="openTab(submission.youtube)">
              <v-text-field v-model="submission.youtube"
                append-icon="mdi-launch"
                disabled
                color="green"
              ></v-text-field>
            </div>
          </span>

          <!-- Download Materials -->
          <span v-if="canDownloadSubmissions && submission.filename">
            <h3 class="mb-10">
              Submission
            </h3>

            <v-btn
              @click="downloadRef(submission.filename)"
              class="mb-30"
              color="green"
              small
              >

              Download
            </v-btn>
          </span>

          <!-- Trophy -->
          <div v-if="submission.status">
            <h3>Trophy</h3>

            <v-radio-group v-model="submission.trophy" :readonly="!canEditTrophies" @change="editTrophy()">
              <v-radio color="green" value="silver">
                <template v-slot:label>
                  <div class="fs-14">
                    <v-icon class="mr-1" small>mdi-trophy-outline</v-icon>
                    <b>Silver</b> - You have done a great job. The solution complies with all the specifications and objectives.
                  </div>
                </template>
              </v-radio>

              <v-radio color="green" value="gold">
                <template v-slot:label>
                  <div class="fs-14">
                    <v-icon color="yellow darken-4" class="mr-1" small>mdi-trophy</v-icon>
                    <b>Gold</b> - The solution is one of the best we've seen. You went above and beyond what was expected.
                  </div>
                </template>
              </v-radio>

              <v-radio color="green" value="platinum">
                <template v-slot:label>
                  <div class="fs-14">
                    <v-icon color="black" class="mr-1" small>mdi-trophy</v-icon>
                    <b>Platinum</b> - The solution is formidable and extraordinary. You've reached the expert level!
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// mixins
import firestore from '@/mixins/firestore.js'
import platform from '@/mixins/platform.js'
import storage from '@/mixins/storage.js'
import ui from '@/mixins/ui.js'
import user from '@/mixins/user.js'

export default {
  mixins: [
    firestore,
    platform,
    storage,
    ui,
    user
  ],

  data () {
    return {
      submission: null,
      submissionDoc: null
    }
  },

  computed: {
    /*
     *
     */
    loaded () {
      return this.submissionDoc && this.submission
    }
  },

  methods: {
    /*
     * Change the trophy that a user received
     */
     editTrophy () {
      // validation
      if (!this.submission.trophy) {
        return
      }

      // payload
      var doc = {}
      doc['assessment.trophy'] = this.submission.trophy

      // database update
      this.fsUpdate('submissions', this.submissionDialogId, doc)
    }
  },

  watch: {
    /*
     * Watch for changes in the submission id
     */
    async submissionDialogId (submissionId) {
      // reset
      this.submissionDoc = null

      // validation
      if (!submissionId) {
        return
      }

      // retrieve firestore document
      this.submissionDoc = await this.fsGet('submissions', submissionId)

      // format the document
      this.submission = {
        date: this.formatDate(this.submissionDoc.timestamp.toDate()),
        feedback: this.submissionDoc.assessment.comments || null,
        filename: this.submissionDoc.filename || null,
        id: this.submissionDoc.id,
        instructor: this.getInstructorEmail(this.submissionDoc.reviewer),
        status: this.submissionDoc.pass,
        title: this.getExerciseTitle(this.submissionDoc.exercise),
        trophy: this.submissionDoc.assessment.trophy || 'silver',
        uuid: this.submissionDoc.exercise,
        youtube: this.submissionDoc.youtube
      }
    }
  }
}
</script>

<style>

</style>