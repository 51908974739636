import { firestoreAction, vuexfireMutations } from 'vuexfire'

import firebase from 'firebase/app'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    curriculums: null,
    exercises: null,
    questions: null,
    instructors: null,
    threads: null
  },

  getters: {
    //
    getCurriculums: state => {
      return state.curriculums
    },

    //
    getExercises: state => {
      return state.exercises
    },

    //
    getQuestions: state => {
      return state.questions
    },

    //
    getInstructors: state => {
      return state.instructors
    },

    //
    getThreads: state => {
      return state.threads
    }
  },

  actions: {
    /*
     * Retrieve the curriculums that the user has access to
     */
    async getCurriculums ({commit}) {
      var call = firebase.functions().httpsCallable('jsonGetCurriculums?timestamp=' + new Date().getTime())

      await call()
        .then(async (response) => {
          if (response.data) {
            await commit('setCurriculums', response.data || {})
          }
        })
        .catch(error => {
          console.error(error)
        })
    },
      
    /*
     * Bind `questions` but only unapproved ones!
     */
    bindQuestions: firestoreAction(({ state, bindFirestoreRef }) => {
      if (!state.questions) {
        return bindFirestoreRef('questions', firebase
          .firestore()
          .collection('questions')
          .where('approved', '==', false)
          .where('sanitized', '==', true)
          .orderBy('timestamp', 'asc')
        )
      }
    }),

    /*
     * Bind `instructors`
     */
    bindInstructors: firestoreAction(({ state, bindFirestoreRef }) => {
      if (!state.instructors) {
        return bindFirestoreRef('instructors', firebase
          .firestore()
          .collection('settings')
          .where('roles', 'array-contains', 'instructor')
        )
      }
    }),

    /*
     * Bind `threads` -- only open ones!
     */
    bindThreads: firestoreAction(({ state, bindFirestoreRef }) => {
      if (!state.threads) {
        return bindFirestoreRef('threads', firebase
          .firestore()
          .collection('threads')
          .where('status', '==', 'open')
        )
      }
    }),

    /*
     * Retrieve the JSON file that provides all the exercises and features that
     * the user has unlocked. Including badges, beginner exercise,
     * roles, statistics etc.
     */
    async getJSONs ({commit}) {
      var call = firebase.functions().httpsCallable('jsonGet?timestamp=' + new Date().getTime())

      await call({domain: window.location.hostname})
        .then(async (response) => {
          if (response.data) {
            /* eslint-disable no-multi-spaces */
            await commit('setExercises',          (response.data['exercises']         || []))
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  },

  mutations: {
    //
    setCurriculums (state, curriculums) {
      state.curriculums = curriculums
    },
    
    //
    setExercises (state, exercises) {
      state.exercises = exercises
    },
    
    ...vuexfireMutations
  }
})