<template>
  <v-card class="fill-height pd-10 unanswered-questions" outlined>
    <v-card-title>
      <div class="text-center w100">
        Unanswered Questions
      </div>
    </v-card-title>

    <v-card-text class="bold fs-50 mb-15 mt-15 text-center w100">
      <div :class="dataCss(questions.length, 1)">
        {{ questions.length }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
// mixins
import platform from '@/mixins/platform.js'
import ui from '@/mixins/ui.js'

export default {
  mixins: [platform, ui]
}
</script>

<style>

</style>