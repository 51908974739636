import Index from '@/views/sales/Index'
import Orders from '@/views/sales/Orders'
import subscriptionsCancelled from '@/views/sales/subscriptions/Cancelled'

export default [
  {
    path: '/sales',
    name: 'sales-index',
    component: Index
  },

  {
    path: '/sales/orders',
    name: 'orders',
    component: Orders
  },

  {
    path: '/sales/subscriptions/cancelled',
    name: 'subscriptions-cancelled',
    component: subscriptionsCancelled
  }
]