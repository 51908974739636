<template>
  <div>
    <v-card v-for="(question, index) in _questions" :key="index" class="mb-5">
      <v-card-title>
        {{ getExerciseTitle(question.uuid) }}
      </v-card-title>

      <v-card-subtitle>
        {{ formatDate(question.timestamp.toDate()) }}
      </v-card-subtitle>

      <v-divider />

      <v-card-text class="fs-15">
        {{ question.data }}
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// mixins
import platform from '@/mixins/platform.js'
import ui from '@/mixins/ui.js'

export default {
  props: ['_questions'],

  mixins: [platform, ui]
}
</script>

<style>

</style>