<template>
  <div class="view width-840">
    <div class="heading-1">
      Solutions
    </div>

    <!-- Search Field -->
    <v-text-field v-model="search"
      :rules="[minimumlength]"
      class="mb-15 search-field"
      clearable
      dense
      filled
      hide-details
      prepend-inner-icon="mdi-magnify"
    ></v-text-field>

    <div class="align-right">
      <v-btn
        @click="list()"
        :loading="loading"
        color="green"
        x-small
        >
        Search UUID
      </v-btn>
    </div>

    <!-- Solutions -->
    <div v-if="solutions && solutions.length">
      <v-card v-for="(solution, index) in solutions" :key="index" class="mb-10 mt-10" flat outlined>
        <v-card-title>
          {{ getExerciseTitle(solution.exercise) }}
        </v-card-title>

        <v-card-text class="fs-15">
          {{ (solution.assessment && solution.assessment.comments) ? solution.assessment.comments : 'no comments' }}
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <span v-if="solution.youtube">
            <a :href="solution.youtube" class="no-decoration" target="_blank">
              YouTube Video
            </a>
          </span>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'

// mixins
import platform from '@/mixins/platform.js'
import ui from '@/mixins/ui.js'

// view
export default {
  mixins: [platform, ui],

  data () {
    return {
      minimumlength: value => value.length >= 4 || 'Minimum 4 characters',
      search: '',
      solutions: []
    }
  },

  methods: {
    /* 
     *
     */
    async list () {
      // reset
      this.solutions = []

      // ui
      this.startOverlay()

      // query
      await firebase.firestore()
        .collection('submissions')
        .where('exercise', '==', this.search)
        .get()
        .then(querySnapshot => {
          for (var i = 0; i < querySnapshot.size; i++) {
            var doc = querySnapshot.docs[i].data()
            var solution = Object.assign({}, doc)
            solution.id = doc.id

            this.solutions.push(solution)
          }
        })
        .catch(err => {
          console.log(err)
        })

      // ui
      this.stopOverlay()
    }
  }
}
</script>

<style>

</style>