import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    navigationMenu: true,
    overlay: false,
    persistentSnackBar: false,
    persistentSnackBarText: null,
    snackbar: false,
    snackbarText: null,
    snackbarColor: '',
    snackbarTimeout: 4000,
    submissionDialog: false,
    submissionDialogId: null
  },

  getters: {
    // State to display or hide the left hand side meny
    navigationMenuStatus: state => {
      return state.navigationMenu
    },

    // Overlay for displaying a loading wheel
    overlay: state => {
      return state.overlay
    },

    /*
     * Snackbar for displaying success or error messages
     */
    persistentSnackBarText: state => {
      return state.persistentSnackBarText
    },

    snackbarStatus: state => {
      return state.snackbar
    },

    snackbarText: state => {
      return state.snackbarText
    },

    snackbarColor: state => {
      return state.snackbarColor
    },

    snackbarTimeout: state => {
      return state.snackbarTimeout
    },

    /*
     * Full screen dialog for displaying a submission
     */
    submissionDialog: state => {
      return state.submissionDialog
    },

    submissionDialogId: state => {
      return state.submissionDialogId
    }
  },

  actions: {
    /*
     *
     */
    closeNavigationMenu ({commit}) {
      commit('navigationMenuFalse')
    },

    /*
     *
     */
    openNavigationMenu ({commit}) {
      commit('navigationMenuTrue')
    }
  },

  mutations: {
    // change the state of the overlay
    changeOverlay (state, val) {
      state.overlay = val
    },

    // close the menu
    navigationMenuFalse (state) {
      state.navigationMenu = false
    },

    // open the menu
    navigationMenuTrue (state) {
      state.navigationMenu = true
    },
    
    // switch the state of the menu
    navigationMenuSwitch (state) {
      state.navigationMenu = !state.navigationMenu
    },

    // close the persistent snackbar
    closePersistentSnackbar (state) {
      state.persistentSnackBar = false
      state.persistentSnackBarText = null
    },

    // close the snackbar
    closeSnackbar (state) {
      state.snackbar = false
    },

    // open the persistent snackbar
    triggerPersistentSnackbar (state, text) {
      state.persistentSnackBar = true
      state.persistentSnackBarText = text
    },

    // show a success message in the snackbar
    triggerSuccessSnackbar (state, text) {
      state.snackbar = true
      state.snackbarColor = 'green'

      if (text['data'] && text['data']['message']) {
        state.snackbarText = text['data']['message']
      } else if (text['data'] && typeof text['data'] === 'string') {
        state.snackbarText = text['data']
      } else {
        state.snackbarText = text
      }

      setTimeout(() => { state.snackbar = false }, state.snackbarTimeout)
    },

    // show an error message in the snackbar
    triggerErrorSnackbar (state, error) {
      state.snackbar = true
      state.snackbarColor = 'error'
      state.snackbarText = error['message'] || error
      
      setTimeout(() => { state.snackbar = false }, state.snackbarTimeout)
    },

    // close a submission dialog
    closeSubmissionDialog (state) {
      state.submissionDialog = false
      state.submissionDialogId = null
    },

    // open the fullscreen dialog to show a submission
    openSubmissionDialog (state, submissionId) {
      state.submissionDialog = true
      state.submissionDialogId = submissionId
    }
  }
})