var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.sortedItems,"items-per-page":20,"dense":""},scopedSlots:_vm._u([{key:"item.studentEmail",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"no-decoration",attrs:{"to":("/student/" + (item.student))}},[_vm._v(" "+_vm._s(item.studentEmail)+" ")])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"no-decoration",attrs:{"to":("/messages/thread/" + (item.id))}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(item.state)?_c('v-chip',{attrs:{"color":_vm.color(index, 'state'),"x-small":""}},[_vm._v(" "+_vm._s(item.state)+" ")]):_vm._e()]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(item.priority)?_c('v-chip',{attrs:{"color":_vm.color(index, 'priority'),"x-small":""}},[_vm._v(" "+_vm._s(item.priority)+" ")]):_vm._e()]}},{key:"item.read",fn:function(ref){
var item = ref.item;
return [(item.read)?_c('v-chip',{attrs:{"color":"green","x-small":""}},[_c('span',{staticClass:"white--text"},[_vm._v(" True ")])]):_vm._e()]}},{key:"item.instructor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getInstructorEmail(item.instructor))+" ")]}},{key:"item.lastUpdated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.lastUpdated.toDate()))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }