<template>
  <v-card color="blue--background" hover raised>
    <v-card-title class="align-center mb-0 subtitle-1">
      Roles:
    </v-card-title>

    <v-data-table
      :items="transform(roles)"
      :headers="headers"
      dense
      hide-default-header
      items-per-page="5"
      >
      <template v-slot:item.product="{ item }">
        {{ formatText(item.label) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
// mixins
import ui from '@/mixins/ui.js'

export default {
  props: ['roles'],

  mixins: [ui],

  data () {
    return {
      headers: [
        {
          sortable: false,
          text: 'Product',
          value: 'product'
        }
      ]
    }
  },

  methods: {
    /*
     *
     */
    transform () {
      return this.roles.map(i => {
        if (!i) {
          return
        }
        
        return {
          label: i
        }
      })
    }
  }
}
</script>

<style>

</style>