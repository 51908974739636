<template>
  <div class="view">
    <!-- Loading bar -->
    <v-progress-linear v-if="!loaded" color="green" :indeterminate="true"></v-progress-linear>

    <div v-else>
      <div class="heading-1">
        Abandoned Exercises
      </div>
      
      <!-- Table -->
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="100"
        class="elevation-1"
        >
        <!-- title -->
        <template v-slot:item.title="{ item }">
          <router-link :to="`/student/${item.uid}`" class="no-decoration">
            {{ item.title }}
          </router-link>
        </template>

        <!-- time -->
        <template v-slot:item.time="{ item }">
          {{ item.timestamp }}
        </template>

        <!-- feedback -->
        <template v-slot:item.feedback="{ item }">
          <a class="no-decoration" @click="viewFeedback(item.feedback)">
            Feedback
          </a>
        </template>
      </v-data-table>
    </div>

    <!-- Dialog -->
    <v-dialog v-model="dialog" width="800">
      <v-card>
        <v-card-title class="mb-0">
          Instructor Feedback
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-textarea v-model="feedback"
            filled
            class="mt-20"
            color="green"
            height="300"
            label="Message"
          ></v-textarea>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase'
import moment from 'moment'

// mixins
import platform from '@/mixins/platform.js'

// view
export default {
  mixins: [platform],

  data () {
    return {
      dialog: false,
      docs: [],
      feedback: '',
      headers: [
        {
          sortable: false,
          text: 'Title',
          value: 'title'
        },

        {
          sortable: false,
          text: 'Submitted',
          value: 'time'
        },

        {
          sortable: false,
          text: 'Feedback',
          value: 'feedback'
        }
      ]
    }
  },

  computed: {
    /*
     * Returns TRUE once the data has loaded
     */
    loaded () {
      return Array.isArray(this.items) && this.items.length > 0
    },

    /*
     *
     */
    items () {
      if (!Array.isArray(this.docs) || this.docs.length === 0) {
        return []
      }

      var res = []

      for (var i = 0; i < this.docs.length; i++) {
        var submission = this.docs[i]
        
        if (!submission.exercise) {
          continue
        }

        var exercise = this.getExercise(submission.exercise)

        if (!exercise || !exercise.title) {
          continue
        }

        res.push({
          category: exercise.groupUUID,
          date: submission.timestamp.toDate(),
          feedback: submission.assessment.comments || '',
          timestamp: this.fromDate(submission.timestamp.toDate()),
          title: exercise.title,
          uid: submission.student
        })
      }

      return res.sort(this.customSort)
    }
  },

  methods: {
    /*
     * Sort the results by date
     */
    customSort (a, b) {
      return b.date - a.date
    },

    /*
     * Returns how many minutes/hours/days ago the submission was lodged
     */
    fromDate (timestamp) {
      return moment(timestamp).fromNow(true)
    },

    /*
     * Display the feedback the the instructor gave
     */
    viewFeedback (message) {
      // clean up
      this.dialog = false

      // open the dialog
      this.feedback = message
      this.dialog = true
    }
  },

  /*
   * Firestore connector
   */
  firestore () {
    return {
      docs: firebase.firestore()
        .collection('submissions')
        .where('pass', '==', false)
        .orderBy('timestamp', 'desc')
        .limit(200)
    }
  }
}
</script>

<style>

</style>