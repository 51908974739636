<template>
  <v-card>
    <v-card-title>
      Platform Metrics
    </v-card-title>

    <v-divider />

    <v-card-text>
      <GChart
        :data="chartData"
        :options="chartOptions"
        class="google-chart"
        type="LineChart"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment'
import { GChart } from 'vue-google-charts/legacy'

export default {
  props: ['format', 'items'],

  computed: {
    /*
     *
     */
    chartData () {
      var res = [['Months', 'Account Creation', 'Submissions', 'Failed Submissions']]

      if (this.items) {
        for (var i = 0; i < this.items.length; i++) {
          var doc = this.items[i]

          // validation
          if (!doc.creationdate) {
            continue
          }

          // retrieve the month
          var key = moment.tz(doc.creationdate.toDate(), 'Australia/Victoria').format(this.format)
          
          // retrieve the values
          var v1 = doc['account-creations'] || 0
          var v2 = doc['submissions'] || 0
          var v3 = doc['failed-submissions'] || 0

          // push the results
          res.push([key, v1, v2, v3])
        }
      }

      return res
    },

    /*
     *
     */
    chartOptions () {
      return {
        height: 300,

        backgroundColor: { fill: 'transparent' },

        colors: ['#59C611', '#FFEB3B', '#B71C1C'],

        legend: {
          textStyle: {
            color: '#FFF'
          }
        },

        hAxis: {
          textStyle: {
            color: '#FFF'
          }
        },

        vAxis: {
          textStyle: {
            color: '#FFF'
          }
        }
      }
    }
  },

  components: {
    GChart
  }
}
</script>

<style>

</style>