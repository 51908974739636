<template>
  <div class="view">
    <!-- Loading bar -->
    <v-progress-linear v-if="!loaded" color="green" :indeterminate="true"></v-progress-linear>

    <div v-else>
      <div class="mb-20 heading">
        <a class="no-decoration white--text" @click="openPublicProfile()">
          Email: {{ email }} | UID: {{ uid }}
        </a>
      </div>

      <v-divider />

      <!-- Tab -->
      <v-tabs v-model="tab" class="mt-20" slider-color="green">
        <v-tab href="#overview">
          Overview
        </v-tab>

        <v-tab href="#journey">
          Journey
        </v-tab>

        <v-tab href="#threads">
          Threads
        </v-tab>

        <v-tab href="#submissions">
          Submissions
        </v-tab>

        <v-tab href="#bookmarks" v-if="bookmarks.length">
          Bookmarks
        </v-tab>

        <v-tab href="#questions" v-if="questions && questions.length">
          Questions
        </v-tab>

        <v-tab href="#orders" v-if="orders && orders.length">
          Orders
        </v-tab>

        <v-tab href="#failed-exercises">
          Failed Exercises
        </v-tab>

        <v-tab href="#browsing-history">
          Browsing History
        </v-tab>

        <v-tabs-items v-model="tab">
          <!-- Overview -->
          <v-tab-item value="overview" v-if="tab === 'overview'">
            <Overview
              :products="products"
              :roles="roles"
              :scores="scores"
              :tags="tags"
              :uid="uid"
              />
          </v-tab-item>

          <!-- Journey -->
          <v-tab-item value="journey" v-if="tab === 'journey'">
            <Journey :uid="uid" />
          </v-tab-item>

          <!-- Threads -->
          <v-tab-item value="threads" v-if="tab === 'threads'">
            <Threads :semail="email" :suid="uid" />
          </v-tab-item>

          <!-- Submissions -->
          <v-tab-item value="submissions" v-if="tab === 'submissions'">
            <Submissions :uid="uid" :trophies="canEditTrophies" :videos="canViewVideos" />
          </v-tab-item>

          <!-- Bookmarks -->
          <v-tab-item value="bookmarks" v-if="tab === 'bookmarks'">
            <Bookmarks :bookmarks="bookmarks" />
          </v-tab-item>

          <!-- Questions -->
          <v-tab-item value="questions" v-if="tab === 'questions'">
            <Questions :_questions="questions" />
          </v-tab-item>

          <!-- Orders -->
          <v-tab-item value="orders" v-if="tab === 'orders'">
            <Orders :orders="orders" />
          </v-tab-item>

          <!-- Failed Exercises -->
          <v-tab-item value="failed-exercises" v-if="tab === 'failed-exercises'">
            <FailedExercises :uid="uid" />
          </v-tab-item>

          <!-- Browsing History -->
          <v-tab-item value="browsing-history" v-if="tab === 'browsing-history'">
            <BrowsingHistory :uid="uid" />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase'

// mixins
import user from '@/mixins/user.js'

// components
import Bookmarks from '@/components/student/data/Bookmarks'
import BrowsingHistory from '@/components/student/data/BrowsingHistory'
import FailedExercises from '@/components/student/data/FailedExercises'
import Journey from '@/components/student/data/Journey'
import Orders from '@/components/student/data/Orders'
import Overview from '@/components/student/Overview'
import Questions from '@/components/student/data/Questions'
import Submissions from '@/components/student/data/Submissions'
import Threads from '@/components/student/Threads'

export default {
  mixins: [user],

  data () {
    return {
      configuration: null,
      questions: null,
      orders: null,
      tab: null,
      scores: null,
      studentSettings: null
    }
  },

  computed: {
    /*
     *
     */
    bookmarks () {
      return this.configuration.bookmarks || []
    },

    /*
     *
     */
    email () {
      return this.studentSettings.email || null
    },

    /*
     *
     */
    loaded () {
      return this.configuration
        && this.scores
        && this.studentSettings
        && this.uid
    },

    /*
     *
     */
    products () {
      return this.studentSettings.bundles || []
    },

    /*
     *
     */
    roles () {
      return this.studentSettings.roles || []
    },

    /*
     *
     */
    tags () {
      return this.configuration.tags || []
    },

    /*
     *
     */
    uid () {
      return this.$route.params.uid || null
    }
  },

  methods: {
    /*
     * Opens a new tab to a student profile
     */
    openPublicProfile () {
      window.open(`https://students.mosse-institute.com/student/${this.uid}`, '_blank')
    }
  },

  /*
   *
   */
  firestore () {
    return {
      configuration: firebase.firestore()
        .collection('configuration')
        .doc(this.uid),

      questions: firebase.firestore()
        .collection('questions')
        .where('sanitized', '==', true)
        .where('uid', '==', this.uid),

      orders: firebase.firestore()
        .collection('orders')
        .where('content.metadata.platformUserId', '==', this.uid)
        .where('eventName', '==', 'order.completed'),
      
      scores: firebase.firestore()
        .collection('scores')
        .doc(this.uid),

      studentSettings: firebase.firestore()
        .collection('settings')
        .doc(this.uid)
    }
  },

  components: {
    Bookmarks,
    BrowsingHistory,
    FailedExercises,
    Journey,
    Orders,
    Overview,
    Questions,
    Submissions,
    Threads
  }
}
</script>

<style>

</style>