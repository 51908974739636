<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="!items"
    dense
    hide-default-footer
    hide-default-header
    >
    <!-- Key -->
    <template v-slot:item.key="{ item }">
      {{ formatText(item.key) }}
    </template>
  </v-data-table>
</template>

<script>
// mixins
import ui from '@/mixins/ui.js'

// component
export default {
  props: ['data', 'keys'],

  mixins: [ui],

  data () {
    return {
      headers: [
        {
          sortable: false,
          text: 'Key',
          value: 'key'
        },

        {
          sortable: false,
          text: 'Value',
          value: 'value'
        }
      ]
    }
  },

  computed: {
    /*
     *
     */
    items () {
      // data validation
      if (!this.data) {
        return []
      }

      // format the data
      var res = []

      for (var i = 0; i < this.keys.length; i++) {
        var key = this.keys[i]

        res.push({ key: key, value: this.data[key] })
      }

      return res
    }
  }
}
</script>

<style>

</style>