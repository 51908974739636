<template>
  <div class="view width-840">
    <div class="heading-1">
      Metrics
    </div>

    <div class="index-menu">
      <div class="title">
        Select a time period
      </div>

      <IndexMenu :items="items" />
    </div>
  </div>
</template>

<script>
// components
import IndexMenu from '@/components/IndexMenu'

// view
export default {
  computed: {
    items () {
      return [
        {
          icon: 'mdi-trophy-outline',
          path: '/metrics/instructor-hall-of-fame',
          text: 'Instructors Metrics'
        },

        {
          icon: 'mdi-calendar-month',
          path: '/metrics/by-time-period/month',
          text: 'Month'
        },

        {
          icon: 'mdi-calendar-month',
          path: '/metrics/by-time-period/year',
          text: 'Year'
        },

        {
          icon: 'mdi-calendar-month',
          path: '/metrics/by-time-period/all-time',
          text: 'All time'
        }
      ]
    }
  },

  components: {
    IndexMenu
  }
}
</script>

<style>

</style>