var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.submissions)?_c('v-progress-linear',{attrs:{"color":"green","indeterminate":""}}):_c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.openSubmissionDialog(item.id)}}},[_vm._v(" "+_vm._s(item.title)+" ")]),(item.trophy && ['gold', 'platinum'].includes(item.trophy))?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":(item.trophy === 'gold') ? 'yellow darken-4' : 'white',"x-small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-trophy ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.trophy)+" ")])])],1):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):(item.status === false)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")]):_c('v-icon',{attrs:{"color":"orange"}},[_vm._v(" mdi-timer-sand-empty ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }