import firebase from 'firebase/app'

// Get a Firestore instance
const firebaseConfig = {
  apiKey: "AIzaSyDCSJzigNuhm_XIwNOj-mO80XfPOXXxGCw",
  authDomain: "cyber-platform-prod.firebaseapp.com",
  databaseURL: "https://cyber-platform-prod.firebaseio.com",
  projectId: "cyber-platform-prod",
  storageBucket: "cyber-platform-prod.appspot.com",
  messagingSenderId: "353461446805",
  appId: "1:353461446805:web:8da80cf69615ff1125539c"
}

// initialize
firebase.initializeApp(firebaseConfig)

// debugging
if (window.location.hostname === 'localhost') {
  firebase.functions().useEmulator('localhost', 5001)
}