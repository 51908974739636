<template>
  <div>
    <!-- Table -->
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="500"
      dense
      >
      <!-- lastSubmittedOn -->
      <template v-slot:item.displayName="{ item }">
        <router-link :to="`/student/${item.id}`" class="no-decoration">
          {{ item.displayName || 'Anonymous' }}
        </router-link>
      </template>

      <!-- Last Seen -->
      <template v-slot:item.lastSeen="{ item }">
        <span v-if="item['last-active-date']">
          {{ ago(timestamp(item['last-active-date'])) }} ago
        </span>
      </template>

      <!-- Last Submitted -->
      <template v-slot:item.lastSubmittedOn="{ item }">
        <span v-if="item.lastSubmittedOn">
          {{ ago(item.lastSubmittedOn) }} ago
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import firebase from 'firebase'

// mixins
import ui from '@/mixins/ui.js'

export default {
  props: ['items'],

  mixins: [ui],

  data () {
    return {
      headers: [
        {
          sortable: false,
          text: 'Display Name',
          value: 'displayName'
        },

        {
          sortable: false,
          text: 'Points',
          value: 'totalPoints'
        },

        {
          sortable: false,
          text: 'Last Seen',
          value: 'lastSeen'
        },

        {
          sortable: false,
          text: 'Last Submitted',
          value: 'lastSubmittedOn'
        }
      ]
    }
  },

  methods: {
    timestamp (dt) {
      return firebase.firestore.Timestamp.fromDate(new Date(dt))
    }
  }
}
</script>

<style>

</style>