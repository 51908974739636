var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.loaded)?_c('v-progress-linear',{attrs:{"color":"green","indeterminate":true}}):_c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.licenses,"items-per-page":-1,"dense":"","sort-by":"email"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"dense":"","elevation":"0","flat":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"float-right mb-2",attrs:{"color":"green","x-small":""},on:{"click":function($event){_vm.createDialog = true}}},[_vm._v(" Create ")])],1)]},proxy:true},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"no-decoration",attrs:{"to":("/student/" + (item.uid))}},[_vm._v(" "+_vm._s(item.email || 'unknown')+" ")])]}},{key:"item.assignedOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.assignedOn.toDate()))+" ")]}},{key:"item.expiry",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.expiry.toDate()))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"green","icon":"","small":""},on:{"click":function($event){_vm.licenseId = item.id; _vm.editDialog = true}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"color":"red","icon":"","small":""},on:{"click":function($event){_vm.licenseId = item.id; _vm.deleteDialog = true}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1),_c('Create',{attrs:{"orgId":_vm.orgId},model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}}),_c('Delete',{attrs:{"licenseId":_vm.licenseId},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}}),_c('Edit',{attrs:{"licenseId":_vm.licenseId},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }