<template>
  <div class="max-width-1000 view">
    <!-- Daily goals for instructors -->
    <div class="heading mb-3">
      Daily Goals
    </div>

    <v-layout row>
      <v-flex class="pd-10" md4>
        <OutstandingQuestions />
      </v-flex>

      <v-flex class="pd-10" md4>
        <OutstandingSubmissions />
      </v-flex>

      <v-flex class="pd-10" md4>
        <OutstandingThreads />
      </v-flex>
    </v-layout>

    <!-- Conversion Metrics -->
    <div v-if="hasPermission('sales')">
      <div class="heading mb-3 mt-20">
        Conversion Metrics
      </div>

      <v-layout row>
        <v-flex class="pd-10" md4>
          <ClosingOpportunities />
        </v-flex>
      </v-layout>
    </div>

    <!-- Performance Metrics -->
    <div v-if="hasPermission('sales')">
      <div class="heading mb-3 mt-20">
        Performance Metrics
      </div>

      <v-layout row>
        <v-flex class="pd-10" md4>
          <ActiveUsers />
        </v-flex>

        <v-flex class="pd-10" md4>
          <ConversionRate />
        </v-flex>

        <v-flex class="pd-10" md4>
          <RepeatRate />
        </v-flex>
      </v-layout>
    </div>

    <!-- Instructor Logs -->
    <div class="heading mb-3 mt-20">
      Instructor Logs
    </div>

    <InstructorLogs />

    <!-- Usage Metrics -->
    <div class="heading mb-3 mt-20">
      Usage Metrics
    </div>

    <v-layout row>
      <v-flex class="pd-10" md4>
        <UsageDay />
      </v-flex>

      <v-flex class="pd-10" md4>
        <UsageWeek />
      </v-flex>

      <v-flex class="pd-10" md4>
        <UsageMonth />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
// mixins
import metrics from '@/mixins/metrics.js'
import user from '@/mixins/user.js'

// components
import ActiveUsers from '@/components/metrics/usage/data/ActiveUsers'
import ClosingOpportunities from '@/components/data/ClosingOpportunities'
import ConversionRate from '@/components/metrics/revenue/data/ConversionRate'
import InstructorLogs from '@/components/data/InstructorLogs'
import OutstandingQuestions from '@/components/data/OutstandingQuestions'
import OutstandingSubmissions from '@/components/data/OutstandingSubmissions'
import OutstandingThreads from '@/components/data/OutstandingThreads'
import RepeatRate from '@/components/metrics/revenue/data/RepeatRate'
import UsageDay from '@/components/metrics/usage/cards/Day'
import UsageMonth from '@/components/metrics/usage/cards/Month'
import UsageWeek from '@/components/metrics/usage/cards/Week'

export default {
  mixins: [
    metrics,
    user
  ],

  components: {
    ActiveUsers,
    ClosingOpportunities,
    ConversionRate,
    InstructorLogs,
    OutstandingQuestions,
    OutstandingSubmissions,
    OutstandingThreads,
    RepeatRate,
    UsageDay,
    UsageMonth,
    UsageWeek
  }
}
</script>

<style>

</style>