<template>
  <div class="view">
    <!-- Loading bar -->
    <v-progress-linear v-if="!loaded" color="green" :indeterminate="true"></v-progress-linear>

    <!-- Analytics -->
    <div v-else>
      <Breadcrumbs :items="crumbs" />

      <div class="heading-1">
        Key Metrics: All time
      </div>

      <!-- Key Metrics Chart -->
      <KeyMetricsLinesChart :items="months" class="mb-15" format="YYYY-MM" />

      <!-- Sales Chart -->
      <SalesLinesChart :items="orders" class="mb-15" format="YYYY-MM" />

      <!-- Summary -->
      <v-card>
        <v-card-text>
          <div v-for="(item, index) in items" :key="index" class="mb-20" style="border: 1px solid black;">
            <div class="body-1 grey lighten-3 pd-10 uppercase">
              {{ item.title }}
            </div>

            <v-divider />

            <v-layout class="pd-10" row>
              <v-flex v-for="(key, index2) in item.items" :key="index2" class="text-xs-center uppercase">
                <div class="bold headline" style="width:100%;">
                  {{ key['value'] || '?'}}
                </div>

                {{ key.label.toUpperCase() }}
              </v-flex>
            </v-layout>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase'

// components
import Breadcrumbs from '@/components/Breadcrumbs'
import KeyMetricsLinesChart from '@/components/metrics/graphs/KeyMetricsLinesChart'
import SalesLinesChart from '@/components/metrics/graphs/SalesLinesChart'

// view
export default {
  data () {
    return {
      crumbs: [
        {
          text: 'Metrics',
          disabled: false,
          href: '/#/metrics',
        },

        {
          text: 'All time',
          disabled: true
        }
      ],

      doc: null,
      months: null,
      orders: null
    }
  },

  computed: {
    /*
     *
     */
    items () {
      if (!this.doc || Object.keys(this.doc).length === 0) {
        return null
      }

      return [
        {
          title: 'Students Performance',
          items: [
            {
              label: 'Submissions',
              value: this.doc['total-submissions']
            },

            {
              label: 'Active Students',
              value: this.doc['active-students']
            },

            {
              label: 'Engagement Rate',
              value: this.doc['percentage-active-students'].toString() + '%'
            },

            {
              label: 'Average Submissions',
              value: this.doc['average-submissions-per-student']
            }
          ]
        },

        {
          title: 'Population 1',
          items: [
            {
              label: '1 Exercise',
              value: this.doc['1-exercise']
            },

            {
              label: '2 Exercises',
              value: this.doc['2-exercises']
            },

            {
              label: '5 Exercises',
              value: this.doc['5-exercises']
            },

            {
              label: '10 Exercises',
              value: this.doc['10-exercises']
            }
          ]
        },

        {
          title: 'Population 2',
          items: [
            {
              label: '30 Exercise',
              value: this.doc['30-exercises']
            },

            {
              label: '50 Exercises',
              value: this.doc['50-exercises']
            },

            {
              label: '75 Exercises',
              value: this.doc['75-exercises']
            },

            {
              label: '100 Exercises',
              value: this.doc['100-exercises']
            }
          ]
        }
      ]
    },

    /*
     *
     */
    loaded () {
      return this.doc
        && this.items
        && this.months
        && this.orders
    }
  },

  /*
   * Firestore connector
   */
  firestore () {
    return {
      doc: firebase.firestore()
        .collection('analytics')
        .doc('performance'),

      months: firebase.firestore()
        .collection('analytics')
        .where('type', '==', 'monthly-analytics')
        .orderBy('creationdate', 'asc'),

      orders: firebase.firestore()
        .collection('orders')
        .where('eventName', 'in', ['order.completed', 'order.refund.created'])
        .orderBy('createdOn', 'desc')
    }
  },

  components: {
    Breadcrumbs,
    KeyMetricsLinesChart,
    SalesLinesChart
  }
}
</script>

<style>

</style>