<template>
  <div class="view width-840">
    <!-- Loading bar -->
    <v-progress-linear v-if="!loaded" color="green" :indeterminate="true"></v-progress-linear>

    <div v-else>
      <div class="heading-1">
        Lost Subscribers
      </div>

      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1"
        >
        <!-- Email -->
        <template v-slot:item.email="{ item }">
          <router-link :to="`/student/${item.uid}`" class="no-decoration">
            {{ item.email }}
          </router-link>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'

// mixins
import firestore from '@/mixins/firestore.js'

export default {
  mixins: [firestore],

  data () {
    return {
      headers: [
        {
          sortable: false,
          text: 'Email',
          value: 'email'
        },

        {
          sortable: false,
          text: 'Status',
          value: 'status'
        }
      ],

      items: [],

      subscriptions: null
    }
  },

  computed: {
    /*
     * Returns TRUE if the data's loaded
     */
    loaded () {
      return this.items && this.items.length > 0
    }
  },

  methods: {
    /*
     *
     */
    async getEmail (uid) {
      var data = await this.fsGet('settings', uid, true)

      // validation
      if (!data || !data.email) {
        return 'unknown'
      }

      // debugging
      window.console.log('Fetched email for uid=' + uid)

      return data.email
    },

    /*
     *
     */
    async getItems () {
      // validation
      if (!Array.isArray(this.subscriptions)) {
        return
      }

      // filter the data
      var _subscriptions = this.subscriptions.filter(s => s.cancelledOn || s.status === 'Paused')

      for (var i = 0; i < _subscriptions.length; i++) {
        var subscription = _subscriptions[i]

        // validation
        if (!subscription.metadata || !subscription.metadata.platformUserId) {
          continue
        }

        // uid
        var uid = subscription.metadata.platformUserId

        // variable
        var item = {}
        
        // data
        item.email  = await this.getEmail(uid)
        item.uid    = uid
        item.status = (subscription.cancelledOn) ? 'Cancelled' : 'Paused'

        this.items.push(item)
      }
    }
  },

  created () {
    this.$bind('subscriptions', firebase.firestore().collection('subscriptions'))
      .then(() => {
        this.getItems()
      })
  }
}
</script>

<style>

</style>