import Vue from 'vue'
import App from './App.vue'
import { firestorePlugin } from 'vuefire'
import router from './router'
import vuetify from './plugins/vuetify'

// plugins
Vue.use(firestorePlugin)

// firestore
import '@/db.js'

/*
 * Custom CSS
 */
import '@/assets/css/helpers.css'
import '@/assets/css/theme.css'
import '@/assets/css/theme-dark.css'
import '@/assets/css/mobile.css'
import '@/assets/css/vuetify.css'
import '@/assets/css/web.css'

/*
 * Layouts
 */
import OneColumn from '@/layouts/OneColumn'
import PublicLayout from '@/layouts/PublicLayout'

Vue.component('application-layout-one-column', OneColumn)
Vue.component('public-layout', PublicLayout)

/*
 * Vue Moment
 */
import moment from 'moment-timezone'
import VueMoment from 'vue-moment'
Vue.use(VueMoment, {moment})

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
