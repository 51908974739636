<template>
  <div>
    <List :items="items" />
  </div>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase'

// components
import List from '@/components/threads/List'

export default {
  data () {
    return {
      items: null
    }
  },

  /*
   * Firestore Connector
   */
  firestore () {
    return {
      items: firebase.firestore()
        .collection('threads')
        .where('status', '==', 'closed')
        .orderBy('lastUpdated', 'desc')
        .limit(100)
    }
  },

  components: {
    List
  }
}
</script>

<style>

</style>