<template>
  <div>
    <!-- Loading Bar -->
    <v-progress-linear v-if="loading" color="green" indeterminate></v-progress-linear>

    <div v-else>
      <List :items="items" />
    </div>
  </div>
</template>

<script>
// mixins
import firestore from '@/mixins/firestore.js'
import user from '@/mixins/user.js'

// components
import List from '@/components/threads/List'

export default {
  mixins: [firestore, user],

  data () {
    return {
      loading: true,
      items: []
    }
  },

  computed: {
    /*
     *
     */
    bookmarks () {
      return this.configuration.bookmarkedThreads || []
    }
  },

  /*
   *
   */
  async mounted () {
    for (var i = 0; i < this.bookmarks.length; i++) {
      var threadId = this.bookmarks[i]

      var thread = await this.fsGet('threads', threadId)

      if (thread) {
        thread.id = threadId

        // save results
        this.items.push(thread)
      }
    }

    // ui
    this.loading = false
  },

  components: {
    List
  }
}
</script>

<style>

</style>