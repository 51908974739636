<template>
  <div>
    <v-select v-model="tags"
      :items="items"
      attach
      chips
      color="green"
      item-color="green"
      multiple
      >
    </v-select>
  </div>
</template>

<script>
// mixins
import firestore from '@/mixins/firestore.js'

export default {
  props: ['uid', 'value'],

  mixins: [firestore],

  data () {
    return {
      items: [
        'Bootcamps',
        'MBT',
        'MCD',
        'MCL',
        'MCPE',
        'MCPT',
        'MCSE',
        'MCSF',
        'MDFIR',
        'MDSO',
        'MGRC',
        'MNSE',
        'MOIS',
        'MPT',
        'MRCI',
        'MRE',
        'MRT',
        'MSAF',
        'MTH',
        'MTIA',
        'MVRE',
        'DO NOT CONTACT'
      ],

      tags: null
    }
  },

  computed: {
    /*
     *
     */
    fieldModelValue: {
      get () {
        return this.value
      }
    }
  },

  /*
   *
   */
  async mounted () {
    if (this.value) {
      this.tags = Object.assign([], this.value)
    } else {
      // if tags are not passed to the component then we try to load from the DB
      var configuration = await this.fsGet('configuration', this.uid, true)

      if (configuration && configuration.tags) {
        this.tags = Object.assign([], configuration.tags)
      }
    }
  },

  /*
   *
   */
  watch: {
    /*
     *
     */
    tags (newVal, oldVal) {
      // ignore the first modification that sets the value
      if (oldVal) {
        var doc = {
          tags: newVal
        }

        // update the database
        this.fsUpdate('configuration', this.uid, doc, true)
      }
    },

    /*
     *
     */
    value (val) {
      if (val) {
        Object.assign([], val)
      }
    }
  }
}
</script>

<style>

</style>