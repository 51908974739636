import FormidableSolutions from '@/views/search/FormidableSolutions'
import ReviewedToday from '@/views/search/ReviewedToday'
import Solutions from '@/views/search/Solutions'

export default [
  {
    path: '/search/reviewed-exercises-today',
    name: 'reviewed-exercises-today',
    component: ReviewedToday
  },

  {
    path: '/search/formidable-solutions',
    name: 'formidable-solutions',
    component: FormidableSolutions
  },

  {
    path: '/search/solutions',
    name: 'search-solutions',
    component: Solutions
  }
]