import { firestoreAction, vuexfireMutations } from 'vuexfire'

import Vuex from 'vuex'
import Vue from 'vue'
import firebase from 'firebase/app'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    configuration: null,
    email: null,
    profile: null,
    settings: null,
    uid: null
  },

  getters: {
    //
    getConfiguration (state) {
      return state.configuration
    },

    //
    getEmail (state) {
      return state.email
    },

    //
    getProfile (state) {
      return state.profile
    },

    //
    getSettings (state) {
      return state.settings
    },

    //
    getUID (state) {
      return state.uid
    }
  },

  actions: {
    /*
     * Binds `configuration`
     */
    bindConfiguration: firestoreAction(({ state, bindFirestoreRef }) => {
      return bindFirestoreRef('configuration', firebase
        .firestore()
        .collection('configuration')
        .doc(state.uid)
      )
    }),
    
    /*
     * Binds `profile`
     */
    bindProfile: firestoreAction(({ state, bindFirestoreRef }) => {
      return bindFirestoreRef('profile', firebase
        .firestore()
        .collection('profiles')
        .doc(state.uid)
      )
    }),

    /*
     * Binds `settings`
     */
    bindSettings: firestoreAction(async ({ state, bindFirestoreRef }) => {
      return bindFirestoreRef('settings', firebase
        .firestore()
        .collection('settings')
        .doc(state.uid)
      )
    })
  },

  mutations: {
    //
    setEmail (state, email) {
      state.email = email
    },

    //
    setUid (state, uid) {
      state.uid = uid
    },

    //
    reset (state) {
      state.uid = null
    },

    ...vuexfireMutations
  }
})
