<template>
  <div class="view">
    <!-- Loading bar -->
    <v-progress-linear v-if="!analytics || analytics.length === 0" color="green" :indeterminate="true"></v-progress-linear>

    <!-- Card -->
    <div v-else>
      <div class="heading-1">
        Top 50 exercises most often failed
      </div>

      <p class="grey--text">
        Please propose ideas that would help students solve these exercises without giving them the answers.
      </p>

      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        class="elevation-1"
        disable-pagination
        hide-default-footer
        sort-by="count"
        sort-desc
        >
        <!-- title -->
        <template v-slot:item.title="{ item }">
          <router-link class="no-decoration" :to="`/education/exercise/${item.groupUID}/${item.uuid}`">
            {{ item.title }}
          </router-link>
        </template>

        <!-- category -->
        <template v-slot:item.category="{ item }">
          {{ formatText(item.groupUID) }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase'

// mixins
import platform from '@/mixins/platform.js'
import ui from '@/mixins/ui.js'

// component
export default {
  mixins: [platform, ui],
  
  data () {
    return {
      analytics: null,

      headers: [
        { text: 'Exercise', sortable: false, value: 'title' },
        { text: 'Category', sortable: false, value: 'category' },
        { text: 'Difficulty', sortable: false, value: 'difficulty' },
        { text: 'Failed Count', sortable: false, value: 'count' }
      ]
    }
  },

  computed: {
    items () {
      return this.exercises
        .filter(e => this.uuids.includes(e.uuid))
        .map(e => Object.assign(e, {count: this.getCount(e.uuid)}))
    },

    uuids () {
      return this.analytics.map(doc => doc.uuid)
    }
  },

  methods: {
    getCount (uuid) {
      return this.analytics.filter(doc => doc.uuid === uuid)[0]['fail-count']
    }   
  },

  firestore () {
    return {
      analytics: firebase.firestore()
        .collection('analytics')
        .where('type', '==', 'exercise-analytics')
        .orderBy('fail-count', 'desc')
        .limit(50)
    }
  }
}
</script>

<style>

</style>