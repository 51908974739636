<template>
  <div class="index-menu-items">
    <v-list rounded>
      <v-list-item v-for="(item, i) in filter(items)" :key="i" :href="item.href" :to="item.path">
        <v-list-item-icon>
          <v-icon large :color="item.color" v-text="item.icon"></v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            {{ item.text }}
            
            <v-chip v-if="item.badge"
              class="badge-red ml-2"
              x-small
              >
              <span class="white--text">
                {{ item.badge }}
              </span>
            </v-chip>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  props: ['items'],

  methods: {
    /*
     *
     */
    display (item) {
      return item.condition === undefined || item.condition === true
    },

    /*
     *
     */
    filter (items) {
      return items.filter(i => this.display(i))
    }
  }
}
</script>

<style>
.index-menu-items {
  max-width: 500px;
}
</style>