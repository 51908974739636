<template>
  <div class="view width-840">
    <div class="heading-1">
      Audit Logs
    </div>

    <!-- Main Menu -->
    <div class="index-menu">
      <div class="title">
        Select a page
      </div>

      <IndexMenu :items="items" />
    </div>

    <!-- Sub Menu -->
    <div class="index-sub-menu mt-50">
      <p>
        Other pages:
      </p>

      <ul class="green-bullets">
        <li>
          <router-link class="no-decoration" to="/audits/roles/enterprise">
            Enterprise Users
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// components
import IndexMenu from '@/components/IndexMenu'

// view
export default {
  computed: {
    items () {
      return [
        {
          icon: 'mdi-alert',
          path: '/audits/abandoned-exercises',
          text: 'Abandoned Exercises'
        },

        {
          icon: 'mdi-alert',
          path: '/audits/most-failed-exercises',
          text: 'Most Failed Exercises'
        }
      ]
    }
  },

  components: {
    IndexMenu
  }
}
</script>

<style>

</style>