import HallOfFame from '@/views/metrics/HallOfFame'
import KeyMetricsAllTime from '@/views/metrics/by-time-period/AllTime'
import Index from '@/views/metrics/Index'

export default [
  {
    path: '/metrics/instructor-hall-of-fame',
    name: 'instructor-hall-of-fame',
    component: HallOfFame
  },

  {
    path: '/metrics',
    name: 'metrics-index',
    component: Index
  },

  {
    path: '/metrics/by-time-period/all-time',
    name: 'metrics-by-time-period-all-time',
    component: KeyMetricsAllTime
  }
]