<template>
  <div>
    <Menu />
    <SubmissionDialog />

    <v-content>
      <LoadingOverlay />

      <slot />
    </v-content>
  </div>
</template>

<script>
// components
import LoadingOverlay from '@/components/LoadingOverlay'
import Menu from '@/components/Menu'
import SubmissionDialog from '@/components/SubmissionDialog'

export default {
  components: {
    LoadingOverlay,
    Menu,
    SubmissionDialog
  }
}
</script>

<style>

</style>
