<template>
  <div class="view">
    <div class="heading-1">
      Threads
    </div>

    <v-tabs v-model="tab" slider-color="green">
      <v-tab href="#open">
        Open
      </v-tab>

      <v-tab href="#closed">
        Closed
      </v-tab>

      <v-tab href="#bookmarked">
        Bookmarked
      </v-tab>

      <v-tabs-items v-model="tab">
        <!-- Open Threads -->
        <v-tab-item value="open" v-if="tab === 'open'">
          <List :items="threads" />
        </v-tab-item>

        <!-- Closed Threads -->
        <v-tab-item value="closed" v-if="tab === 'closed'">
          <ClosedThreads />
        </v-tab-item>

        <!-- Bookmarked -->
        <v-tab-item value="bookmarked" v-if="tab === 'bookmarked'">
          <BookmarkedThreads />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
// mixins
import platform from '@/mixins/platform.js'

// components
import BookmarkedThreads from '@/components/threads/BookmarkedThreads'
import ClosedThreads from '@/components/threads/ClosedThreads'
import List from '@/components/threads/List'

export default {
  mixins: [platform],

  data () {
    return {
      tab: null
    }
  },

  components: {
    BookmarkedThreads,
    ClosedThreads,
    List
  }
}
</script>

<style>

</style>