<template>
  <div class="view width-840">
    <!-- Loading bar -->
    <v-progress-linear v-if="!loaded" color="green" :indeterminate="true"></v-progress-linear>

    <div v-else>
      <div class="heading-1">
        Search by product
      </div>

      <!-- Search Field -->
      <v-select v-model="select"
        :items="filter(curriculums)"
        color="green"
        filled
        item-text="curriculum-title"
        item-value="sku"
        hide-details
        >
      </v-select>

      <v-data-table v-if="docs && docs.length"
        :headers="headers"
        :items="docs"
        class="mt-30"
        >
        <!-- email -->
        <template v-slot:item.email="{ item }">
          <router-link class="no-decoration" :to="`/student/${item.id}`">
            {{ item.email }}
          </router-link>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
// mixins
import firestore from '@/mixins/firestore.js'
import platform from '@/mixins/platform.js'
import ui from '@/mixins/ui.js'

export default {
  mixins: [firestore, platform, ui],

  data () {
    return {
      docs: null,
      headers: [
        {
          sortable: false,
          text: 'Email',
          value: 'email'
        }
      ],

      select: null
    }
  },

  computed: {
    /*
     *
     */
    loaded () {
      return this.curriculums && this.curriculums.length
    }
  },

  methods: {
    /*
     *
     */
    filter (items) {
      // only retrieve certifications
      var res = items.filter(i => !i.private && i.available)
        .filter(i => i.type === 'mcsi-curriculum')
        .filter(i => i.sku !== 'mics-introduction-to-cyber-security')

      // add the bootcamps
      res.push({
        'curriculum-title': 'Bootcamps',
        'sku': 'mcsi-bootcamps'
      })

      // return results
      return res
    },

    /*
     *
     */
    async search (sku) {
      this.docs = await this.fsSearch('settings', 'bundles', 'array-contains', sku)
    }
  },

  watch: {
    /*
     *
     */
    select (sku) {
      this.search(sku)
    }
  }
}
</script>

<style>

</style>