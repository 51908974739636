<template>
  <div>
    <!-- Dialog to delete a license -->
    <v-dialog v-model="value" width="300">
      <v-card>
        <v-card-text class="align-center fs-16 pd-15">
          Are you sure you want to delete this license?
        </v-card-text>

        <v-card-actions>
          <v-btn
            @click="this.value = false"
            :disabled="loading"
            small
            >
            Cancel
          </v-btn>

          <v-spacer />

          <v-btn
            @click="deleteLicense()"
            :loading="loading"
            color="red"
            small
            >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// mixins
import firestore from '@/mixins/firestore.js'

export default {
  props: ['licenseId', 'value'],

  mixins: [firestore],

  data () {
    return {
      loading: false
    }
  },

  computed: {
    /*
     *
     */
    fieldModelValue: {
      get () {
        return this.value
      },
      
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },

  methods: {
    /*
     *
     */
    deleteLicense: async function () {
      //
      this.loading = true

      await this.fsDelete('licenses', this.licenseId)

      //
      this.loading = false

      // close the dialog
      this.value = false
    }
  },

  watch: {
    /*
     *
     */
    value (newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>

<style>

</style>