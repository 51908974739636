<template>
  <div class="view width-1000">
    <!-- Loading bar -->
    <v-progress-linear v-if="!students || students.length < 2" color="green" indeterminate></v-progress-linear>

    <!-- View -->
    <div v-else>
      <div class="heading-1">
        Hall of Fame
      </div>

      <v-data-table
        :headers="headers"
        :items="students"
        :items-per-page="100"
        :mobile-breakpoint="0"
        class="elevation-1"
        dense
        no-data-text="No data loaded"
        >
        <template v-slot:item="{ item, index }">
          <tr>
            <td>
              {{ index + 1 }}
            </td>

            <td>
              <router-link :to="`/student/${item.id}`" class="no-decoration">
                <span v-if="item.displayName">
                  {{ item.displayName }}
                </span>

                <span v-else>
                  Anonymous
                </span>
              </router-link>
            </td>

            <!-- Points -->
            <td class="align-center">
              {{ item.totalPoints }}
            </td>

            <!-- Exercises Completed -->
            <td class="align-center hidden-sm-and-down">
              {{ getExercisesCompleted(item) }}
            </td>

            <!-- Last Active Date -->
            <td class="align-center hidden-sm-and-down">
              <span v-if="item['last-active-date']">
                {{ ago2(item['last-active-date']) }} ago
              </span>

              <span v-else>
                unknown
              </span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase'

// mixins
import ui from '@/mixins/ui.js'

// view
export default {
  mixins: [ui],

  data () {
    return {
      headers: [
        {
          text: 'Rank',
          sortable: false
        },

        {
          text: 'Student',
          sortable: false
        },

        {
          align: 'center',
          text: 'Score',
          sortable: false
        },

        {
          align: 'center',
          class: 'hidden-sm-and-down',
          text: 'Nb. Exercises',
          sortable: false
        },

        {
          align: 'center',
          class: 'hidden-sm-and-down',
          text: 'Last Seen',
          sortable: false
        }
      ],

      students: null
    }
  },

  methods: {
    /*
     *
     */
    getExercisesCompleted (scores) {
      return scores.exercisesCompleted.total || 0
    }
  },

  /*
   * Firestore Connector
   */
  firestore () {
    return {
      students: firebase.firestore()
        .collection('scores')
        .orderBy('totalPoints', 'desc')
        .limit(400)
    }
  }
}
</script>

<style>
.level-chip {
  border-radius: 2px;
  font-size: 10px;
  padding: 3px !important;
  margin-left: 5px;
}

.label-practitioner {
  background: #198f3d;
  color: white;
}
</style>
