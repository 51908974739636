import { firestoreAction, vuexfireMutations } from 'vuexfire'

import firebase from 'firebase/app'
import moment from 'moment'
import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    day: null,
    month: null,
    performance: null,
    week: null
  },

  getters: {
    //
    getDay (state) {
      return state.day
    },

    //
    getMonth (state) {
      return state.month
    },

    //
    getPerformance (state) {
      return state.performance
    },

    //
    getWeek (state) {
      return state.week
    }
  },

  actions: {
    /*
     * Binds monthly analytics document
     */
    bindDay: firestoreAction(({ state, bindFirestoreRef }) => {
      if (!state.month) {
        var docId = moment.tz(new Date(), 'Australia/Victoria').startOf('day').format('YYYY-MM-DD')

        return bindFirestoreRef('day', firebase
          .firestore()
          .collection('analytics')
          .doc(`analytics_day_${docId}`)
        )
      }
    }),

    /*
     * Binds monthly analytics document
     */
    bindMonth: firestoreAction(({ state, bindFirestoreRef }) => {
      if (!state.month) {
        var docId = moment.tz(new Date(), 'Australia/Victoria').startOf('month').format('YYYY-MM-DD')

        return bindFirestoreRef('month', firebase
          .firestore()
          .collection('analytics')
          .doc(`analytics_month_${docId}`)
        )
      }
    }),

    /*
     * Binds platform's analytics performance document
     */
    bindPerformance: firestoreAction(({ state, bindFirestoreRef }) => {
      if (!state.performance) {
        return bindFirestoreRef('performance', firebase
          .firestore()
          .collection('analytics')
          .doc('performance')
        )
      }
    }),

    /*
     * Binds weekly analytics document
     */
    bindWeek: firestoreAction(({ state, bindFirestoreRef }) => {
      if (!state.week) {
        var docId = moment.tz(new Date(), 'Australia/Victoria').startOf('isoWeek').format('YYYY-MM-DD')
        
        return bindFirestoreRef('week', firebase
          .firestore()
          .collection('analytics')
          .doc(`analytics_week_${docId}`)
        )
      }
    })
  },

  mutations: {
    ...vuexfireMutations
  }
})
