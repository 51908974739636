<template>
  <div>
    <!-- Loading bar -->
    <v-progress-linear v-if="!loaded" color="green" :indeterminate="true"></v-progress-linear>
    
    <!-- Tables -->
    <v-layout v-else row>
      <v-flex v-for="(column, index) in columns" :key="index" class="pd-10">
        <v-card>
          <v-card-title>
            {{ column.title }}
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="itemize(column.keys)"
            :items-per-page="-1"
            dense
            hide-default-footer
            hide-default-header
            >
            <!-- Value -->
            <template v-slot:item.value="{ item }">
              <v-icon v-if="item.value" color="green" small>
                mdi-check
              </v-icon>

              <v-icon v-else color="orange" small>
                mdi-alert
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase'

// mixins
import ui from '@/mixins/ui.js'

export default {
  props: ['uid'],

  mixins: [ui],

  data () {
    return {
      columns: [
        {
          title: 'Onboarding',
          keys: [
            'account-created',
            'email-verified',
            'completed-onboarding',
            'viewed-dashboard',
            'viewed-curriculum',
            'watched-onboarding-video',
            'viewed-exercise',
            'submitted-exercise',
            'read-feedback',
            'viewed-shopping-cart',
            'purchased',
            'repeat-buyer'
          ]
        },

        {
          title: 'Features',
          keys: [
            'used-themes',
            'used-bookmarks',
            'used-chat',
            'used-quick-questions',
            'used-profile',
            'used-library',
            'used-teams',
            'used-videos',
            'used-friend-invitation',
            'used-faq'
          ]
        },

        {
          title: 'Milestones',
          keys: [
            'lab-setup-exercise',
            'passed-1-exercise',
            'passed-2-exercises',
            'passed-5-exercises',
            'passed-10-exercises',
            'resubmitted-a-failed-exercise',
            'gold-trophy',
            'platinum-trophy'
          ]
        }
      ],

      headers: [
        {
          sortable: false,
          text: 'Key',
          value: 'key'
        },

        {
          sortable: false,
          text: 'Value',
          value: 'value'
        }
      ],

      tags: null
    }
  },

  computed: {
    /*
     * Returns TRUE if the data has loaded
     */
    loaded () {
      return this.tags && this.tags.email
    }
  },

  methods: {
    /*
     *
     */
    itemize (keys) {
      var res = []

      for (var i = 0; i < keys.length; i++) {
        var key = keys[i]

        res.push({
          key: this.formatText(key),
          value: this.tags[key] === true
        })
      }

      return res
    }
  },

  /*
   * Firestore Connector
   */
   firestore () {
    return {
      tags: firebase.firestore()
        .collection('tags')
        .doc(this.uid)
    }
  }
}
</script>

<style>

</style>