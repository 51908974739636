<template>
  <div class="view width-840">
    <div class="heading-1">
      Students
    </div>

    <!-- Search Field -->
    <v-text-field v-model="search"
      :rules="[minimumlength]"
      class="mb-15 search-field"
      clearable
      color="green"
      dense
      filled
      hide-details
      prepend-inner-icon="mdi-magnify"
    ></v-text-field>

    <div class="align-right">
      <v-btn
        @click="searchStudents()"
        :loading="loading"
        color="green"
        x-small
        >
        Search
      </v-btn>
    </div>

    <!-- Index Menu -->
    <div class="index-menu mt-50">
      <div class="title">
        Select a page
      </div>

      <IndexMenu :items="menu" />
    </div>

    <!-- Sub Menu -->
    <div class="index-sub-menu mt-50">
      <p>
        Search by number of exercises completed:
      </p>

      <ul class="green-bullets">
        <li>
          <router-link class="no-decoration" to="/students/search/1-exercise">
            1 exercise
          </router-link>
        </li>

        <li>
          <router-link class="no-decoration" to="/students/search/2-exercises">
            2 exercises
          </router-link>
        </li>

        <li>
          <router-link class="no-decoration" to="/students/search/5-exercises">
            5 exercises
          </router-link>
        </li>
      </ul>
    </div>

    <!-- Sub Menu -->
    <div class="index-sub-menu mt-20">
      <p>
        Other pages:
      </p>

      <ul class="green-bullets">
        <li>
          <router-link class="no-decoration" to="/search/reviewed-exercises-today">
            Find exercises that were reviewed today
          </router-link>
        </li>

        <li>
          <router-link class="no-decoration" to="/search/solutions">
            Search solutions for an exercise
          </router-link>
        </li>

        <li>
          <router-link class="no-decoration" to="/search/formidable-solutions">
            View solutions that received a gold or platinum trophy
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// mixins
import firestore from '@/mixins/firestore.js'
import regex from '@/mixins/regex.js'
import ui from '@/mixins/ui.js'

// components
import IndexMenu from '@/components/IndexMenu'

export default {
  mixins: [firestore, regex, ui],

  data () {
    return {
      menu: [
      {
          color: 'teal',
          icon: 'mdi-account-school',
          path: '/students/search/browsed-shopping-cart',
          text: 'Browsed shopping cart'
        },

        {
          color: 'red',
          icon: 'mdi-account-school',
          path: '/students/active/today',
          text: 'Active Students: Today'
        },

        {
          color: 'orange',
          icon: 'mdi-account-school',
          path: '/students/active/week',
          text: 'Active Students: This Week'
        },

        {
          color: 'purple',
          icon: 'mdi-account-school',
          path: '/students/active/month',
          text: 'Active Students: This Month'
        },

        {
          color: 'deep-purple',
          icon: 'mdi-account-school',
          path: '/students/active/hall-of-fame',
          text: 'Hall of Fame'
        },

        {
          color: 'indigo',
          icon: 'mdi-account-school',
          path: '/students/search/by-product',
          text: 'Search by product'
        },

        {
          color: 'blue',
          icon: 'mdi-account-school',
          path: '/students/search/by-organization',
          text: 'Search by organization'
        }
      ],

      minimumlength: value => value.length >= 4 || 'Minimum 4 characters',
      search: ''
    }
  },

  methods: {
    /*
     *
     */
    searchStudents: async function () {
      if (!this.search) {
        return
      }

      // data reset
      this.students = []

      // overlay
      this.startOverlay()

      // search by document id
      var query_1 = await this.fsGet('settings', this.search, true)

      if (query_1) {
        // overlay
        this.stopOverlay()

        // redirect
        return this.$router.push(`/student/${this.search}`)
      }

      // search by email
      var query_2 = await this.fsSearch('settings', 'email', '==', this.search, true)

      if (query_2 && query_2.length) {
        // overlay
        this.stopOverlay()

        // redirect
        var uid = query_2[0].id

        return this.$router.push(`/student/${uid}`)
      }

      // overlay
      this.stopOverlay()

      // error message
      this.throwError('No user found')
    }
  },

  components: {
    IndexMenu
  }
}
</script>

<style>

</style>