import firebase from 'firebase'

// mixins
import ui from '@/mixins/ui.js'

export default {
  mixins: [ui],

  methods: {
    /*
     * Obtains a download URL for a storage object and opens it in a new window
     */
    downloadRef (ref) {
      firebase.storage()
        .ref(ref)
        .getDownloadURL()
        .then(uri => {
          return window.open(uri, '_blank')
        })
        .catch((err) => {
          return this.throwError(err)
        })
    }
  }
}
