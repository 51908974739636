<template>
  <div class="view">
    <v-progress-linear v-if="!loaded" color="green" :indeterminate="true"></v-progress-linear>

    <div v-else>
      <div class="heading-1">
        {{ title }}
      </div>

      <v-tabs v-model="tab" slider-color="green">
        <v-tab href="#users">
          Users
        </v-tab>

        <v-tab href="#invitations">
          Invitations
        </v-tab>

        <v-tab href="#licenses">
          Licenses
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <!-- Users-->
        <v-tab-item value="users" v-if="tab === 'users'">
          <Users :orgId="orgId" />
        </v-tab-item>

        <!-- Invitations-->
        <v-tab-item value="invitations" v-if="tab === 'invitations'">
          <Invitations :orgId="orgId" />
        </v-tab-item>

        <!-- Licenses-->
        <v-tab-item value="licenses" v-if="tab === 'licenses'">
          <Licenses :orgId="orgId" />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase'

// components
import Invitations from '@/components/organization/Invitations'
import Licenses from '@/components/organization/Licenses'
import Users from '@/components/organization/Users'

export default {
  data () {
    return {
      organization: null,
      tab: null
    }
  },

  computed: {
    /*
     *
     */
    loaded () {
      return this.organization
    },

    /*
     *
     */
     orgId () {
      return this.$route.params.orgId || null
    },

    /*
     *
     */
     title () {
      return this.organization.description || ''
    }
  },

  /*
   * Firestore Connector
   */
  firestore () {
    return {
      organization: firebase.firestore()
        .collection('organisations')
        .doc(this.orgId)
    }
  },

  components: {
    Invitations,
    Licenses,
    Users
  }
}
</script>

<style>

</style>