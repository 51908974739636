// stores
import metrics from '@/stores/metrics.js'

export default {
  computed: {
    /*
     *
     */
    platformPerformance () {
      return metrics.getters.getPerformance
    },

    /*
     *
     */
    usageDay () {
      return metrics.getters.getDay
    },

    /*
     *
     */
    usageWeek () {
      return metrics.getters.getWeek
    },

    /*
     *
     */
    usageMonth () {
      return metrics.getters.getMonth
    }
  },

  /*
   *
   */
  mounted () {
    if (!this.usageDay) {
      metrics.dispatch('bindDay')
      metrics.dispatch('bindMonth')
      metrics.dispatch('bindPerformance')
      metrics.dispatch('bindWeek')
    }
  }
}