<template>
  <div class="view width-840">
    <!-- Loading bar -->
    <v-progress-linear v-if="!loaded" color="green" :indeterminate="true"></v-progress-linear>

    <div v-else>
      <div class="heading-1">
        Search students by organization
      </div>

      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        sort-by="description"
        dense
        >
        <!-- Description -->
        <template v-slot:item.description="{ item }">
          <router-link :to="`/organization/${item.id}`" class="no-decoration">
            {{ item.description || 'unknown' }}
          </router-link>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase'

export default {
  data () {
    return {
      headers: [
        {
          sortable: false,
          text: 'Name',
          value: 'description'
        }
      ],

      items: null
    }
  },

  computed: {
    /*
     *
     */
    loaded () {
      return this.items && this.items.length > 0
    }
  },

  /*
   * Firestore Connector
   */
  firestore () {
    return {
      items: firebase.firestore()
        .collection('organisations')
    }
  }
}
</script>

<style>

</style>