// stores
import ui from '@/stores/ui.js'

// mixin
export default {
  computed: {
    /*
     *
     */
    menu () {
      return ui.getters.navigationMenuStatus
    },

    /*
     * Returns TRUE if the user is on a mobile device
     */
    mobile () {
      return window.screen.width <= 1264
    },

    /*
     * Retrieve the status of the menu bar
     */
    opened () {
      return ui.getters.navigationMenuStatus
    }
  },

  methods: {
    /*
     *
     */
    switchMenuState () {
      ui.commit('navigationMenuSwitch')
    }
  },

  mounted () {
    /*
     * UX: By default, we close the naviguation menu for mobile
     * and tablet devices.
     */
    if (!this.mobile) {
      ui.dispatch('openNavigationMenu')
    } else {
      ui.dispatch('closeNavigationMenu')
    }
  }
}
