<template>
  <div>
    <!-- Loading bar -->
    <v-progress-linear v-if="!loaded" color="green" :indeterminate="true"></v-progress-linear>

    <div v-else>     
      <v-data-table
        :headers="headers"
        :items="invitations"
        :items-per-page="-1"
        dense
      ></v-data-table>
    </div>
  </div>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase'

export default {
  props: ['orgId'],

  data () {
    return {
      headers: [
        {
          sortable: false,
          text: 'Email',
          value: 'email'
        },

        {
          sortable: false,
          text: 'Accepted',
          value: 'accepted'
        },

        {
          sortable: false,
          text: 'From',
          value: 'from'
        }
      ],

      invitations: null
    }
  },

  computed: {
    /*
     *
     */
    loaded () {
      return this.invitations && this.invitations.length > 0
    }
  },

  /*
   * Firestore Connector
   */
  firestore () {
    return {
      invitations: firebase.firestore()
        .collection('friends-invitations')
        .where('organisation', '==', this.orgId)
    }
  }
}
</script>

<style>

</style>