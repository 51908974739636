<template>
  <div class="mx-auto view width-1000">
    <!-- Loading Bar -->
    <v-progress-linear v-if="!thread" color="green" indeterminate></v-progress-linear>

    <div v-else>
      <!-- Metadata -->
      <div class="mb-20">
        Thread Id: {{ threadId }}
      </div>

      <!-- Thread Data -->
      <v-card>
        <v-card-title class="title">
          {{ thread.title }}
        </v-card-title>

        <v-divider />

        <v-card-text class="fs-14">
          <v-layout row>
            <!-- Created On -->
            <v-flex md2>
              <div class="mb-3 white--text">
                Created On:
              </div>

              {{ createdOnDate }}
            </v-flex>

            <!-- Created On -->
            <v-flex md2>
              <div class="mb-3 white--text">
                Last Updated:
              </div>

              {{ lastUpdatedDate }}
            </v-flex>

            <!-- Status -->
            <v-flex md2 style="padding-right: 20px;">
              <div class="white--text">
                Status:
              </div>

              <v-select v-model="status"
                :items="statuses"
                color="green"
                dense
                hide-details
                item-color="green"
              ></v-select>
            </v-flex>

            <!-- Priorities -->
            <v-flex md2 style="padding-right: 20px;">
              <div class="white--text">
                Priority:
              </div>

              <v-select v-model="priority"
                :items="priorities"
                color="green"
                dense
                hide-details
                item-color="green"
              ></v-select>
            </v-flex>

            <!-- Assigned To -->
            <v-flex md4>
              <div class="white--text">
                Assigned To:
              </div>
              
              <v-select v-model="instructor"
                :items="filter(instructors)"
                color="green"
                dense
                hide-details
                item-text="email"
                item-value="id"
                item-color="green"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn
            @click="bookmarkThread()"
            :color="(isBookmarked) ? 'red' : 'green'"
            :loading="bookmarking"
            icon
            >
            <v-icon>
              {{ (isBookmarked) ? 'mdi-bookmark' : 'mdi-bookmark-outline' }}
            </v-icon>
          </v-btn>

          <v-spacer />

          <v-btn @click="save()" color="green" small>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- Messages -->
      <div v-for="(message, index) in messages" :key="index">
        <Message :last="index === (messages.length - 1)" :message="message" /> 
      </div>

      <!-- Tags -->
      <Tags :uid="thread.student" />

      <!-- New Post -->
      <div v-if="!closed">
        <v-textarea v-model="text"
          class="mt-20"
          color="green"
          counter="2000"
          filled
          height="300"
        ></v-textarea>

        <div class="align-right">
          <v-btn
            @click="reply()"
            :disabled="!text || text.length > 2000"
            color="green"
            small
            >
            Reply
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase'

// mixins
import firestore from '@/mixins/firestore.js'
import platform from '@/mixins/platform.js'
import ui from '@/mixins/ui.js'
import user from '@/mixins/user.js'

// components
import Message from '@/components/threads/Message'
import Tags from '@/components/student/data/Tags'

export default {
  mixins: [firestore, platform, ui, user],

  data () {
    return {
      createdOn: null,
      instructor: null,
      lastUpdated: null,
      priority: null,
      status: null,

      priorities: [
        'low',
        'medium',
        'high'
      ],

      statuses: [
        'open',
        'closed'
      ],

      messages: null,
      text: null,
      thread: null,

      bookmarking: false
    }
  },

  computed: {
    /*
     * Returns TRUE if the thread is closed
     */
    closed () {
      return this.thread.status === 'closed'
    },

    /*
     *
     */
    createdOnDate () {
      if (!this.createdOn) {
        return null
      }

      return this.formatDate(this.thread.createdOn.toDate())
    },

    /*
     * Returns TRUE if the user has bookmarked this thread
     */
    isBookmarked () {
      return this.configuration.bookmarkedThreads
        && this.configuration.bookmarkedThreads.includes(this.thread.id)
    },

    /*
     *
     */
    lastUpdatedDate () {
      if (!this.lastUpdated) {
        return null
      }

      return this.formatDate(this.lastUpdated.toDate())
    },

    /*
     *
     */
    threadId () {
      return this.$route.params.threadId || null
    }
  },

  methods: {
    /*
     *
     */
    async bookmarkThread () {
      var doc = null

      if (this.isBookmarked) {
        doc = {
          bookmarkedThreads: firebase.firestore.FieldValue.arrayRemove(this.thread.id)
        }
      } else {
        doc = {
          bookmarkedThreads: firebase.firestore.FieldValue.arrayUnion(this.thread.id)
        }
      }

      // ui
      this.bookmarking = true

      // database
      await this.fsUpdate('configuration', this.uid, doc)

      // ui
      this.bookmarking = false
    },

    /*
     * Remove disables instructors
     */
    filter (instructors) {
      if (!instructors) {
        return []
      }

      return instructors.filter(i => !i.disabled)
    },

    /*
     * Create a new comment (reply to thread)
     */
    async reply () {
      var doc = {
        displayName: this.displayName,
        read: null,
        sanitized: false,
        text: this.text,
        threadId: this.threadId,
        timestamp: new Date(),
        uid: this.uid
      }

      // ui
      this.startOverlay()

      // database
      if(await this.fsCreate('messages', doc, 'Posted! Wait 15 seconds the post to appear.')) {
        this.text = null
      }

      // ui
      this.stopOverlay()
    },

    /*
     * Change the settings of a thread
     */
    async save () {
      var doc = {
        instructor: this.instructor,
        priority: this.priority,
        status: this.status
      }

      // ui
      this.startOverlay()

      // database update
      await this.fsUpdate('threads', this.threadId, doc)

      // ui
      this.stopOverlay()
    }
  },

  watch: {
    /*
     *
     */
    thread (newVal) {
      this.safeCopyObjects(newVal, this.$data)
    }
  },

  /*
   * Firestore Connector
   */
  firestore () {
    return {
      messages: firebase.firestore()
        .collection('messages')
        .where('threadId', '==', this.threadId)
        .where('sanitized', '==', true)
        .orderBy('timestamp', 'asc'),

      thread: firebase.firestore()
        .collection('threads')
        .doc(this.threadId)
    }
  },

  components: {
    Message,
    Tags
  }
}
</script>

<style>

</style>