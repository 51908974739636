<template>
  <div class="view">
    <!-- Loading bar -->
    <v-progress-linear v-if="!loaded" color="green" :indeterminate="true"></v-progress-linear>

    <div v-else>
      <v-data-table
        :headers="headers"
        :items="docs"
        >
        <!-- Timestamp -->
        <template v-slot:item.timestamp="{ item }">
          {{ formatDate(item.timestamp.toDate()) }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase'

// mixins
import ui from '@/mixins/ui.js'

export default {
  props: ['uid'],

  mixins: [ui],

  data () {
    return {
      docs: null,

      headers: [
        {
          sortable: false,
          text: 'Activity',
          value: 'text'
        },

        {
          sortable: false,
          text: 'Timestamp',
          value: 'timestamp'
        }
      ]
    }
  },

  computed: {
    /*
     *
     */
    loaded () {
      return this.docs && this.docs.length
    }
  },

  /*
   * Firestore Connector
   */
  firestore () {
    return {
      docs: firebase.firestore()
        .collection('activity-logs')
        .where('sanitized', '==', true)
        .where('type', '==', 'browsing')
        .where('uid', '==', this.uid)
        .orderBy('timestamp', 'desc')
        .limit(200)
    }
  }
}
</script>

<style>

</style>