<template>
  <!-- Application layout and views -->
  <v-app id="platform">
    <!-- Loading Bar -->
    <v-progress-linear v-if="!isPublicRoute && !loaded" color="green" indeterminate></v-progress-linear>

    <!-- Router View -->
    <component v-else :is="layout">
      <router-view />

      <!-- Normal Snackbar -->
      <v-snackbar
        :color="snackbarColor"
        :multi-line="true"
        :timeout="snackbarTimeout"
        :value="snackbar"
        >

        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn dark text @click="closeSnackbar" v-bind="attrs">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <!-- Persistent Snackbar -->
      <v-snackbar
        :bottom="true"
        :multi-line="true"
        :timeout="0"
        :value="persistentSnackbarText"
        color="green"
        >
        {{ persistentSnackbarText }}

        <template>
          <v-btn dark text @click="closePersistentSnackbar">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </component>
  </v-app>
</template>

<script>
import firebase from 'firebase'

// stores
import platform from '@/stores/platform.js'
import user from '@/stores/user.js'

// mixins
import platformMixins from '@/mixins/platform.js'
import uiMixins from '@/mixins/ui.js'
import userMixins from '@/mixins/user.js'

// view
export default {
  mixins: [platformMixins, uiMixins, userMixins],
  
  computed: {
    /*
     * Logic to detect the layout that should be loaded
     */
    layout () {
      if (this.$route.meta.layout) {
        return this.$route.meta.layout
      }

      return 'application-layout-one-column'
    },

    /*
     * Return TRUE if the user is logged in and the platform is fully loaded
     */
    loaded () {
      return this.authenticated
        && this.authorized
        && this.exercises.length
        && this.instructors.length
        && this.profile
    },

    /*
     * Return TRUE if the route is a public route
     */
    isPublicRoute () {
      return this.$route.meta.public || false
    }
  },

  /*
   * Before create
   */
  beforeCreate () {
    /*
     * Detect authentication notifications
     */
    firebase.auth().onAuthStateChanged(async (u) => {
      if (u) {
        // set the pre-requisite variables and values first
        await user.commit('setEmail', u.email)
        await user.commit('setUid', u.uid)
        await user.dispatch('bindSettings')

        if (this.authorized) {
          // 
          platform.dispatch('getCurriculums')
          platform.dispatch('bindQuestions')
          platform.dispatch('bindInstructors')
          platform.dispatch('bindThreads')
          platform.dispatch('getJSONs')

          //
          user.dispatch('bindConfiguration')
          user.dispatch('bindProfile')
        }
      }
    })
  }
}
</script>
