<template>
  <div class="view">
    <!-- Loading bar -->
    <v-progress-linear v-if="!loaded" color="green" :indeterminate="true"></v-progress-linear>

    <!-- View -->
    <div v-else>
      <div class="heading-1">
        Student who only submitted 2 exercises
      </div>

      <GenericTable :items="items" />
    </div>
  </div>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase'

// components
import GenericTable from '@/components/students/GenericTable'

export default {
  data () {
    return {
      items: null
    }
  },

  computed: {
    /*
     * Returns TRUE if the data has loaded
     */
    loaded () {
      return this.items && this.items.length > 0
    }
  },

  /*
   * Firestore Connector
   */
   firestore () {
    return {
      items: firebase.firestore()
        .collection('scores')
        .where('exercisesCompleted.total', '==', 2)
    }
  },

  components: {
    GenericTable
  }
}
</script>

<style>

</style>