// stores
import user from '@/stores/user.js'

// class
export default {
  computed: {
    // return TRUE if the user is authenticated
    authenticated () {
      return this.uid !== null
    },

    // return TRUE if the user is authorized to access this interface
    authorized () {
      return this.permissions.includes('admin-console')
    },

    // Returns TRUE if the instructor can download submissions
    canDownloadSubmissions () {
      return this.permissions.includes('download-submissions')
    },

    // Returns TRUE if the instructor can edit trophies
    canEditTrophies () {
      return this.permissions.includes('edit-trophies')
    },

    // Returns TRUE if the instructor can view videos
    canViewVideos () {
      return this.permissions.includes('view-videos')
    },

    // return the user's configuration settings
    configuration () {
      return user.getters.getConfiguration || {}
    },

    // return the user's display name
    displayName () {
      return this.profile.displayName || 'MCSI'
    },
    
    // retrieve the student email address
    email () {
      return user.getters.getEmail
    },
    
    // retrieve the user's permissions
    permissions () {
      if (!Array.isArray(this.settings.permissions)) {
        return []
      }

      return this.settings.permissions
    },

    // retrieve the user's profile document
    profile () {
      return user.getters.getProfile || {}
    },

    // retrieve the user's roles
    roles () {
      if (!Array.isArray(this.settings.roles)) {
        return []
      }

      return this.settings.roles
    },

    // retrieve the student's settings
    settings () {
      return user.getters.getSettings || {}
    },

    // retrieve the user's unique user id
    uid () {
      return user.getters.getUID
    }
  },

  methods: {
    /*
     *
     */
    hasPermission (permission) {
      return this.permissions.includes(permission)
    },

    /*
     * Reset the student store to force them to authenticate back into the
     * platform.
     */
    userStoreReset () {
      user.commit('reset')
    }
  }
}
