<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="sortedItems"
      :items-per-page="20"
      dense
      >
      <!-- Email -->
      <template v-slot:item.studentEmail="{ item }">
        <router-link :to="`/student/${item.student}`" class="no-decoration">
          {{ item.studentEmail }}
        </router-link>
      </template>

      <!-- Title -->
      <template v-slot:item.title="{ item }">
        <router-link :to="`/messages/thread/${item.id}`" class="no-decoration">
          {{ item.title }}
        </router-link>
      </template>

      <!-- State -->
      <template v-slot:item.state="{ item, index }">
        <v-chip v-if="item.state"
          :color="color(index, 'state')"
          x-small
          >

          {{ item.state }}
        </v-chip>
      </template>

      <!-- Priority -->
      <template v-slot:item.priority="{ item, index }">
        <v-chip v-if="item.priority"
          :color="color(index, 'priority')"
          x-small
          >

          {{ item.priority }}
        </v-chip>
      </template>

      <!-- Read -->
      <template v-slot:item.read="{ item }">
        <v-chip v-if="item.read" color="green" x-small>
          <span class="white--text">
            True
          </span>
        </v-chip>
      </template>

      <!-- Instructor -->
      <template v-slot:item.instructor="{ item }">
        {{ getInstructorEmail(item.instructor) }}
      </template>

      <!-- Date -->
      <template v-slot:item.lastUpdated="{ item }">
        {{ formatDate(item.lastUpdated.toDate()) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
// mixins
import platform from '@/mixins/platform.js'
import ui from '@/mixins/ui.js'

export default {
  props: ['items'],

  mixins: [platform, ui],

  data () {
    return {
      headers: [
        {
          sortable: false,
          text: 'Student',
          value: 'studentEmail'
        },

        {
          sortable: false,
          text: 'Title',
          value: 'title'
        },

        {
          sortable: false,
          text: 'State',
          value: 'state'
        },

        {
          sortable: false,
          text: 'Priority',
          value: 'priority'
        },

        {
          sortable: false,
          text: 'Read',
          value: 'read'
        },

        {
          sortable: false,
          text: 'Agent',
          value: 'instructor'
        },

        {
          sortable: false,
          text: 'Last Updated',
          value: 'lastUpdated'
        }
      ]
    }
  },

  computed: {
    /*
     *
     */
    sortedItems () {
      if (!this.items) {
        return []
      }

      return this.items
        .slice(0)
        .sort((a, b) => b.lastUpdated.toDate() - a.lastUpdated.toDate())
    }
  },

  methods: {
    /*
     *
     */
    color (i, field) {
      var str = this.sortedItems[i][field]

      if (!str) {
        return null
      }

      if (str === 'high') {
        return 'red'
      }

      if (str === 'medium') {
        return 'orange'
      }

      if (str === 'student responded') {
        return 'blue'
      }

      return null
    }
  }
}
</script>

<style>

</style>