<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
// mixins
import ui from '@/mixins/ui.js'

// component
export default {
  mixins: [ui]
}
</script>

<style>

</style>