<template>
  <div>
    <!-- Table -->
    <v-data-table
      :items="items"
      :headers="headers"
      :page.sync="page"
      :search="search"
      class="elevation-1"
      >
      <!-- Email -->
      <template v-slot:item.email="{ item }">
        <a :href="`https://students.mosse-institute.com/student/${item.id}`" class="no-decoration" target="_blank">
          {{ item.email }}
        </a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// mixins
import platform from '@/mixins/platform.js'
import ui from '@/mixins/ui.js'

// component
export default {
  props: ['orders'],

  mixins: [platform, ui],
  
  data () {
    return {
      headers: [
        {
          sortable: false,
          text: 'Email',
          value: 'email'
        },

        {
          sortable: false,
          text: 'Invoice Nb.',
          value: 'invoice'
        },

        {
          sortable: false,
          text: 'Item(s)',
          value: 'items'
        },

        {
          sortable: false,
          text: 'Total',
          value: 'total'
        },

        {
          sortable: false,
          text: 'Date',
          value: 'date'
        }
      ],

      page: 1
    }
  },

  computed: {
    /*
     *
     */
    items () {
      if (!this.orders.length) {
        return []
      }

      var orders = this.filter(this.orders)
      var res = []

      for (var i = 0; i < orders.length; i++) {
        var order = orders[i]

        res.push({
          id: order.content.metadata.platformUserId,
          email: order.content.email,
          invoice: order.content.invoiceNumber,
          items: this.showCurriculums(order.content.items),
          total: `$${order.content.total}`,
          date: this.formatDate(order.createdOn)
        })
      }

      return res
    }
  },

  methods: {
    /*
     *
     */
    filter (orders) {
      return orders.filter(i => i.content.total !== 0)
    },

    /*
     *
     */
    showCurriculums (items) {
      var out = ''

      for (var i = 0; i < items.length; i++) {
        var item = this.formatText(items[i].id)

        if (i === 0) {
          out = item
        } else {
          out += ', ' + item
        }
      }

      return out
    }
  }
}
</script>

<style>

</style>