<template>
  <v-card color="blue--background" hover raised>
    <v-card-title class="align-center mb-0 subtitle-1">
      Overview:
    </v-card-title>

    <v-data-table
      :items="items"
      :headers="headers"
      dense
      hide-default-header
      items-per-page="5"
      >
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  props: ['scores'],

  data () {
    return {
      headers: [
        {
          sortable: false,
          text: 'Key',
          value: 'key'
        },

        {
          sortable: false,
          text: 'Value',
          value: 'value'
        }
      ]
    }
  },

  computed: {
    /*
     *
     */
    items () {
      var res = []

      // define variables
      var completed = this.scores.exercisesCompleted

      // add values to the results
      res.push({ key: 'Display Name', value: this.scores.displayName || 'n/a' })
      res.push({ key: 'Exercises Completed', value: completed.total || 0 })
      res.push({ key: 'Total Points', value: this.scores.totalPoints || 0 })
      res.push({ key: 'Last Seen', value: this.scores['last-active-date'] || 'unknown' })
      res.push({ key: 'Nb. Logins', value: this.scores['active-logins'] || 1 })

      return res
    }
  }
}
</script>

<style>

</style>